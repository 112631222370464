.navbar {
  height: 84px;
  flex-shrink: 0;

  :global(.container),
  .left,
  .center,
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  :global(.container) {
    justify-content: space-between;
  }

}

.left {
  button {
    cursor: pointer;
    outline: none;
    padding: 0;
    border: none;
    background: none;
  }
}

.right {

}

.center {

}

.logo {
  height: 48px;
}
