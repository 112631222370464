.frontPageContainer {
}

.titleContainer {
  margin-top: 5px;
}

.section {
  padding: 24px 0;

  .title {
    margin: 10px 0;
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid #D9D9D9;
  }
}

.newSessionButtonContainer {
  display: flex;
  justify-content: center;
}
