@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 720px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 720px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.container {
  padding-left: 24px;
  padding-right: 24px; }

html {
  background: fixed linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 22.4%, #D9D9D9 100%); }

html,
body {
  height: 100%; }

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333; }

#root {
  height: 100%; }

a {
  text-decoration: none;
  outline: none; }

h1 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300; }

h2 {
  font-weight: 300;
  font-size: 22px;
  line-height: 30px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.navbar_navbar__1pbIf {
  height: 84px;
  flex-shrink: 0; }
  .navbar_navbar__1pbIf .container,
  .navbar_navbar__1pbIf .navbar_left__3uaHM,
  .navbar_navbar__1pbIf .navbar_center__3vQzV,
  .navbar_navbar__1pbIf .navbar_right__HlUuy {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
  .navbar_navbar__1pbIf .container {
    justify-content: space-between; }

.navbar_left__3uaHM button {
  cursor: pointer;
  outline: none;
  padding: 0;
  border: none;
  background: none; }

.navbar_logo__1nWOE {
  height: 48px; }

.currentSong_albumImage__eNAOL {
  overflow: hidden;
  margin-right: 16px;
  height: 80px;
  padding-left: 8px; }
  .currentSong_albumImage__eNAOL .currentSong_img__1BJtJ {
    height: 100%; }

.currentSong_info__3GPdG {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.currentSong_songName__7AK3z {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px; }

.currentSong_songArtist__3C2Ps {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 0; }

@media (min-width: 720px) {
  .currentSong_albumImage__eNAOL {
    height: 120px;
    margin-right: 24px; } }

.progress_container__1qfTU {
  width: 100%;
  height: 2px;
  background: #5B5B5B;
  position: relative; }

.progress_progress__3uJqs {
  background: #B21ED7;
  width: 0;
  position: absolute;
  height: 100%;
  left: 0; }
  .progress_progress__3uJqs:before {
    content: '';
    position: absolute;
    width: 10px;
    background: inherit;
    height: 5px;
    border-radius: 2px;
    right: 0;
    top: 0;
    -webkit-transform: translate(50%, calc(-50% + 1px));
            transform: translate(50%, calc(-50% + 1px)); }

.queue_row__25S9l,
.queue_col__1W6Ql {
  height: 100%;
  overflow: auto;
  display: flex;
  flex: 1 1; }

.queue_song__3FAW7 {
  margin-bottom: 16px; }

.queue_albumImage__CmewI {
  overflow: hidden;
  margin-right: 8px;
  height: 48px;
  width: 48px;
  padding-left: 8px; }
  .queue_albumImage__CmewI img {
    height: 100%; }

.queue_info__3ul5F {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.queue_songName__3BDzF {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px; }

.queue_songArtist__3FQdZ {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 0; }

@media (min-width: 720px) {
  .queue_albumImage__CmewI {
    height: 60px;
    width: 60px;
    margin-right: 16px; } }

.song_song__gvzih {
  margin-bottom: 16px; }
  .song_song__gvzih.song_clickable__2cVp2 {
    cursor: pointer; }

.song_albumImage__2GTIX {
  overflow: hidden;
  margin-right: 8px;
  height: 60px;
  padding-left: 8px; }
  .song_albumImage__2GTIX img {
    height: 100%; }

.song_info__3U7MN {
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.song_songName__2hZqu {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px; }

.song_songArtist__W8nl9 {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 0; }

@media (min-width: 720px) {
  .song_albumImage__2GTIX {
    height: 80px;
    margin-right: 16px; } }

.songList_songList__tmAun {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%; }

.songList_song__3Mt5V {
  margin-top: 16px; }

.search_headerContainer__2K47d {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0; }
  .search_headerContainer__2K47d h1 {
    margin: 0; }

.search_sessionContainer__1tOJG {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .search_sessionContainer__1tOJG .search_contentContainer__18YaV {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden;
    transition: all 0.3s ease-in;
    height: 100%; }
  .search_sessionContainer__1tOJG.search_searchOpen__6Jn6j .search_contentContainer__18YaV {
    flex: 0 1;
    height: 0; }
  .search_sessionContainer__1tOJG.search_searchOpen__6Jn6j .search_searchContainer__1Fkgz {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
  .search_sessionContainer__1tOJG:not(.search_searchOpen__6Jn6j) .search_searchContainer__1Fkgz {
    flex-grow: 0;
    -webkit-animation: search_position__3Knyy 0s;
            animation: search_position__3Knyy 0s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

.search_backgroundGradient__11t1x {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 88px;
  z-index: 1;
  transition: background-position 0.3s linear; }
  .search_backgroundGradient__11t1x.search_open__3Hukt {
    background-position: 0 45px;
    z-index: 3; }

.search_searchContainer__1Fkgz {
  z-index: 2;
  width: 100%;
  transition: flex-grow 0.3s, height 0.3s; }

.search_progress__1bhVv,
.search_queue__3XjCQ {
  margin-top: 32px; }

.search_queue__3XjCQ {
  flex: 1 1; }

.search_search__1Xk3x {
  width: 100%; }

.search_searchBarOpen__1fWR3 {
  flex: 1 1; }

@-webkit-keyframes search_position__3Knyy {
  from {
    position: relative; }
  to {
    position: absolute;
    bottom: 0;
    left: 0; } }

@keyframes search_position__3Knyy {
  from {
    position: relative; }
  to {
    position: absolute;
    bottom: 0;
    left: 0; } }

.search_container__kmcTb {
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: text;
  transition: background-position 0.3s linear;
  flex: 0 1; }
  .search_container__kmcTb.search_active__2gKyi .search_input__2YK-n {
    background-color: #CFCFCF; }

.search_input__2YK-n {
  display: block;
  border: none;
  height: 56px;
  width: 100%;
  border-radius: 28px;
  background-color: #FFFFFF;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  outline: none;
  transition: all 0.3s ease-in; }

.search_searchResultContainer__mYbVh {
  flex: 0 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 8px;
  transition: flex-grow 0.3s linear;
  height: 0; }
  .search_searchResultContainer__mYbVh.search_active__2gKyi {
    height: 100%;
    flex-grow: 1; }

.search_song__2CL7K {
  margin-bottom: 16px; }

.search_albumImage__2t_5W {
  overflow: hidden;
  margin-right: 8px;
  height: 48px;
  width: 48px;
  padding-left: 8px; }
  .search_albumImage__2t_5W img {
    height: 100%; }

.search_info__1KVET {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.search_songName__2Tced {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px; }

.search_songArtist__3AZZV {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 0; }

@media (min-width: 720px) {
  .search_albumImage__2t_5W {
    height: 60px;
    width: 60px;
    margin-right: 16px; } }

.session_headerContainer__3KSzv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0; }
  .session_headerContainer__3KSzv h1 {
    margin: 0; }

.session_sessionContainer__lDwOK {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .session_sessionContainer__lDwOK .session_contentContainer__3aWuO {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden;
    transition: all 0.3s ease-in;
    height: 100%; }
  .session_sessionContainer__lDwOK.session_searchOpen__3VpzO .session_contentContainer__3aWuO {
    flex: 0 1;
    height: 0; }
  .session_sessionContainer__lDwOK.session_searchOpen__3VpzO .session_searchContainer__EwY0c {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
  .session_sessionContainer__lDwOK:not(.session_searchOpen__3VpzO) .session_searchContainer__EwY0c {
    flex-grow: 0;
    -webkit-animation: session_position__yI6Qo 0s;
            animation: session_position__yI6Qo 0s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

.session_backgroundGradient__xl8U_ {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 88px;
  z-index: 1;
  transition: background-position 0.3s linear; }
  .session_backgroundGradient__xl8U_.session_open__2_V1D {
    background-position: 0 45px;
    z-index: 3; }

.session_searchContainer__EwY0c {
  z-index: 2;
  width: 100%;
  transition: flex-grow 0.3s, height 0.3s; }

.session_progress__37Taj,
.session_queue__2k4WA {
  margin-top: 32px; }

.session_queue__2k4WA {
  flex: 1 1; }

.session_search__1Xxlu {
  width: 100%; }

.session_searchBarOpen__DMhMS {
  flex: 1 1; }

@-webkit-keyframes session_position__yI6Qo {
  from {
    position: relative; }
  to {
    position: absolute;
    bottom: 0;
    left: 0; } }

@keyframes session_position__yI6Qo {
  from {
    position: relative; }
  to {
    position: absolute;
    bottom: 0;
    left: 0; } }

.button_btn__3i5NC {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  height: 36px;
  border-radius: 18px;
  padding-left: 32px;
  padding-right: 32px;
  outline: none;
  box-shadow: none;
  margin-top: 16px;
  font-size: 12px;
  cursor: pointer;
  border: none; }
  .button_btn__3i5NC.button_purple__2MobU {
    background-color: #b21ed7;
    color: #ffffff; }
  .button_btn__3i5NC.button_red__2YCPs {
    background-color: #c00000;
    color: #ffffff; }
  .button_btn__3i5NC:disabled {
    background-color: #666666;
    cursor: not-allowed; }

.button_hidden__2ZYrQ.button_purple__2MobU {
  color: #b21ed7; }

.button_hidden__2ZYrQ.button_red__2YCPs {
  color: #c00000; }

.button_loader__3Zemc {
  position: absolute; }

.loader_container__9lu-0 {
  padding: 2rem 0;
  display: flex;
  justify-content: center; }

/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.tindercard_container__rbFcJ,
.tindercard_container-fluid__2Vq8l,
.tindercard_container-sm__1VO03,
.tindercard_container-md__2HeE1,
.tindercard_container-lg__1VcEI,
.tindercard_container-xl__2jRHd {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .tindercard_container__rbFcJ, .tindercard_container-sm__1VO03 {
    max-width: 540px; } }

@media (min-width: 768px) {
  .tindercard_container__rbFcJ, .tindercard_container-sm__1VO03, .tindercard_container-md__2HeE1 {
    max-width: 720px; } }

@media (min-width: 992px) {
  .tindercard_container__rbFcJ, .tindercard_container-sm__1VO03, .tindercard_container-md__2HeE1, .tindercard_container-lg__1VcEI {
    max-width: 720px; } }

@media (min-width: 1200px) {
  .tindercard_container__rbFcJ, .tindercard_container-sm__1VO03, .tindercard_container-md__2HeE1, .tindercard_container-lg__1VcEI, .tindercard_container-xl__2jRHd {
    max-width: 720px; } }

.tindercard_row__1neNo {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.tindercard_no-gutters__560CE {
  margin-right: 0;
  margin-left: 0; }
  .tindercard_no-gutters__560CE > .tindercard_col__f6wM4,
  .tindercard_no-gutters__560CE > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.tindercard_col-1__2PIpH, .tindercard_col-2__1wZw-, .tindercard_col-3__1V6kj, .tindercard_col-4__USQbD, .tindercard_col-5__2Et-G, .tindercard_col-6__2Y170, .tindercard_col-7__145Am, .tindercard_col-8__2B-5Q, .tindercard_col-9__rXxTK, .tindercard_col-10__2AKqY, .tindercard_col-11__1EfQt, .tindercard_col-12__UZpVZ, .tindercard_col__f6wM4,
.tindercard_col-auto__1XSBM, .tindercard_col-sm-1__M45HY, .tindercard_col-sm-2__2hIV0, .tindercard_col-sm-3__1KloY, .tindercard_col-sm-4__1lE_a, .tindercard_col-sm-5__1kJY8, .tindercard_col-sm-6__2vrzu, .tindercard_col-sm-7__YrX8W, .tindercard_col-sm-8__36tLI, .tindercard_col-sm-9__3olVU, .tindercard_col-sm-10__3bnr6, .tindercard_col-sm-11__2rTel, .tindercard_col-sm-12__23Mwi, .tindercard_col-sm__3wPRq,
.tindercard_col-sm-auto__3iaES, .tindercard_col-md-1__3dasn, .tindercard_col-md-2__sfsjp, .tindercard_col-md-3__3m8Fa, .tindercard_col-md-4__2CQMA, .tindercard_col-md-5__fcbcN, .tindercard_col-md-6__3sPMM, .tindercard_col-md-7__2fV2c, .tindercard_col-md-8__310Oh, .tindercard_col-md-9__gyjwB, .tindercard_col-md-10__13WR-, .tindercard_col-md-11__2tWe2, .tindercard_col-md-12__2o_ag, .tindercard_col-md__3C7TI,
.tindercard_col-md-auto__1zrYR, .tindercard_col-lg-1__1-_kX, .tindercard_col-lg-2__YSV1V, .tindercard_col-lg-3__1q0_q, .tindercard_col-lg-4__3SAg0, .tindercard_col-lg-5__1gO1s, .tindercard_col-lg-6__v4EpP, .tindercard_col-lg-7__2TZWE, .tindercard_col-lg-8__2c1_P, .tindercard_col-lg-9__oMAZ0, .tindercard_col-lg-10__2k2Ch, .tindercard_col-lg-11__39HS5, .tindercard_col-lg-12__1aoQX, .tindercard_col-lg__1TIQj,
.tindercard_col-lg-auto__3PqE6, .tindercard_col-xl-1__2FIiA, .tindercard_col-xl-2__6c2lW, .tindercard_col-xl-3__1Et_2, .tindercard_col-xl-4__1V5gl, .tindercard_col-xl-5__2CIs1, .tindercard_col-xl-6__1gU4R, .tindercard_col-xl-7__1E5z1, .tindercard_col-xl-8__1HWvp, .tindercard_col-xl-9__3xjbc, .tindercard_col-xl-10__1rGt1, .tindercard_col-xl-11__1g-_n, .tindercard_col-xl-12__67KaM, .tindercard_col-xl__2DdKI,
.tindercard_col-xl-auto__3UQ8m {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.tindercard_col__f6wM4 {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.tindercard_row-cols-1__31VZ7 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.tindercard_row-cols-2__GbAoY > * {
  flex: 0 0 50%;
  max-width: 50%; }

.tindercard_row-cols-3__1rFY9 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.tindercard_row-cols-4__1C5Sb > * {
  flex: 0 0 25%;
  max-width: 25%; }

.tindercard_row-cols-5__mgXsE > * {
  flex: 0 0 20%;
  max-width: 20%; }

.tindercard_row-cols-6__27oqc > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.tindercard_col-auto__1XSBM {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.tindercard_col-1__2PIpH {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.tindercard_col-2__1wZw- {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.tindercard_col-3__1V6kj {
  flex: 0 0 25%;
  max-width: 25%; }

.tindercard_col-4__USQbD {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.tindercard_col-5__2Et-G {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.tindercard_col-6__2Y170 {
  flex: 0 0 50%;
  max-width: 50%; }

.tindercard_col-7__145Am {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.tindercard_col-8__2B-5Q {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.tindercard_col-9__rXxTK {
  flex: 0 0 75%;
  max-width: 75%; }

.tindercard_col-10__2AKqY {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.tindercard_col-11__1EfQt {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.tindercard_col-12__UZpVZ {
  flex: 0 0 100%;
  max-width: 100%; }

.tindercard_order-first__Rp1Cb {
  order: -1; }

.tindercard_order-last__z7EDo {
  order: 13; }

.tindercard_order-0__1rKv7 {
  order: 0; }

.tindercard_order-1__34sUA {
  order: 1; }

.tindercard_order-2__36zQX {
  order: 2; }

.tindercard_order-3__LXLXl {
  order: 3; }

.tindercard_order-4__2_Wud {
  order: 4; }

.tindercard_order-5__3I92Z {
  order: 5; }

.tindercard_order-6__37k0Y {
  order: 6; }

.tindercard_order-7__ogUux {
  order: 7; }

.tindercard_order-8__2otDs {
  order: 8; }

.tindercard_order-9__3p7Qe {
  order: 9; }

.tindercard_order-10__3RWXi {
  order: 10; }

.tindercard_order-11__2Grfc {
  order: 11; }

.tindercard_order-12__1EnSE {
  order: 12; }

.tindercard_offset-1__1xWNY {
  margin-left: 8.33333%; }

.tindercard_offset-2__N5oBN {
  margin-left: 16.66667%; }

.tindercard_offset-3__2Ys_L {
  margin-left: 25%; }

.tindercard_offset-4__3EBHv {
  margin-left: 33.33333%; }

.tindercard_offset-5__1gz_K {
  margin-left: 41.66667%; }

.tindercard_offset-6__3HZ8u {
  margin-left: 50%; }

.tindercard_offset-7__2qjse {
  margin-left: 58.33333%; }

.tindercard_offset-8__Qudiv {
  margin-left: 66.66667%; }

.tindercard_offset-9__2aCV7 {
  margin-left: 75%; }

.tindercard_offset-10__3oqRZ {
  margin-left: 83.33333%; }

.tindercard_offset-11__3Rfkv {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .tindercard_col-sm__3wPRq {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .tindercard_row-cols-sm-1__UG8Nv > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_row-cols-sm-2__XrCrJ > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_row-cols-sm-3__1DgTL > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_row-cols-sm-4__3uXcC > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_row-cols-sm-5__1YByE > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .tindercard_row-cols-sm-6__1dfaD > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-sm-auto__3iaES {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .tindercard_col-sm-1__M45HY {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .tindercard_col-sm-2__2hIV0 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-sm-3__1KloY {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_col-sm-4__1lE_a {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_col-sm-5__1kJY8 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .tindercard_col-sm-6__2vrzu {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_col-sm-7__YrX8W {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .tindercard_col-sm-8__36tLI {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .tindercard_col-sm-9__3olVU {
    flex: 0 0 75%;
    max-width: 75%; }
  .tindercard_col-sm-10__3bnr6 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .tindercard_col-sm-11__2rTel {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .tindercard_col-sm-12__23Mwi {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_order-sm-first__2yyHR {
    order: -1; }
  .tindercard_order-sm-last__1Wg16 {
    order: 13; }
  .tindercard_order-sm-0__1zGWS {
    order: 0; }
  .tindercard_order-sm-1__1wtMH {
    order: 1; }
  .tindercard_order-sm-2__1JVX_ {
    order: 2; }
  .tindercard_order-sm-3__2MPgz {
    order: 3; }
  .tindercard_order-sm-4__1UmiK {
    order: 4; }
  .tindercard_order-sm-5__If-OG {
    order: 5; }
  .tindercard_order-sm-6__3m-Ir {
    order: 6; }
  .tindercard_order-sm-7__KH25C {
    order: 7; }
  .tindercard_order-sm-8__13BpP {
    order: 8; }
  .tindercard_order-sm-9__1kiFE {
    order: 9; }
  .tindercard_order-sm-10__14F5o {
    order: 10; }
  .tindercard_order-sm-11__2WYxp {
    order: 11; }
  .tindercard_order-sm-12__2Ss9- {
    order: 12; }
  .tindercard_offset-sm-0__1itX7 {
    margin-left: 0; }
  .tindercard_offset-sm-1__2Imay {
    margin-left: 8.33333%; }
  .tindercard_offset-sm-2__3b00o {
    margin-left: 16.66667%; }
  .tindercard_offset-sm-3__1CPJb {
    margin-left: 25%; }
  .tindercard_offset-sm-4__2CLai {
    margin-left: 33.33333%; }
  .tindercard_offset-sm-5__3ZrvY {
    margin-left: 41.66667%; }
  .tindercard_offset-sm-6__2PG0b {
    margin-left: 50%; }
  .tindercard_offset-sm-7__KbFuv {
    margin-left: 58.33333%; }
  .tindercard_offset-sm-8__1tuhH {
    margin-left: 66.66667%; }
  .tindercard_offset-sm-9__2Aa6G {
    margin-left: 75%; }
  .tindercard_offset-sm-10__3NANU {
    margin-left: 83.33333%; }
  .tindercard_offset-sm-11__2_uNX {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .tindercard_col-md__3C7TI {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .tindercard_row-cols-md-1__1VqzN > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_row-cols-md-2__1hE4- > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_row-cols-md-3__3kJd6 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_row-cols-md-4__1WPJA > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_row-cols-md-5__20E76 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .tindercard_row-cols-md-6__3ukCq > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-md-auto__1zrYR {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .tindercard_col-md-1__3dasn {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .tindercard_col-md-2__sfsjp {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-md-3__3m8Fa {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_col-md-4__2CQMA {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_col-md-5__fcbcN {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .tindercard_col-md-6__3sPMM {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_col-md-7__2fV2c {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .tindercard_col-md-8__310Oh {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .tindercard_col-md-9__gyjwB {
    flex: 0 0 75%;
    max-width: 75%; }
  .tindercard_col-md-10__13WR- {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .tindercard_col-md-11__2tWe2 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .tindercard_col-md-12__2o_ag {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_order-md-first__NTwdL {
    order: -1; }
  .tindercard_order-md-last__tA5Hg {
    order: 13; }
  .tindercard_order-md-0__2U2sm {
    order: 0; }
  .tindercard_order-md-1__39zEB {
    order: 1; }
  .tindercard_order-md-2__1LB43 {
    order: 2; }
  .tindercard_order-md-3__3ZoXx {
    order: 3; }
  .tindercard_order-md-4__2Qu4o {
    order: 4; }
  .tindercard_order-md-5__350-6 {
    order: 5; }
  .tindercard_order-md-6__2Gxs1 {
    order: 6; }
  .tindercard_order-md-7__t_1Ow {
    order: 7; }
  .tindercard_order-md-8__1-nON {
    order: 8; }
  .tindercard_order-md-9__1d0qO {
    order: 9; }
  .tindercard_order-md-10__1AoD_ {
    order: 10; }
  .tindercard_order-md-11__3LOni {
    order: 11; }
  .tindercard_order-md-12__1TEYG {
    order: 12; }
  .tindercard_offset-md-0__2Ylz8 {
    margin-left: 0; }
  .tindercard_offset-md-1__2bie6 {
    margin-left: 8.33333%; }
  .tindercard_offset-md-2__QsKbv {
    margin-left: 16.66667%; }
  .tindercard_offset-md-3__4hP_C {
    margin-left: 25%; }
  .tindercard_offset-md-4__3cSwY {
    margin-left: 33.33333%; }
  .tindercard_offset-md-5__3Ha9D {
    margin-left: 41.66667%; }
  .tindercard_offset-md-6__2JiWc {
    margin-left: 50%; }
  .tindercard_offset-md-7__327bM {
    margin-left: 58.33333%; }
  .tindercard_offset-md-8__1MGZQ {
    margin-left: 66.66667%; }
  .tindercard_offset-md-9__33u_O {
    margin-left: 75%; }
  .tindercard_offset-md-10__2CvQB {
    margin-left: 83.33333%; }
  .tindercard_offset-md-11__1qgD3 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .tindercard_col-lg__1TIQj {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .tindercard_row-cols-lg-1__25BGn > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_row-cols-lg-2__Qt1oY > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_row-cols-lg-3__1GpfD > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_row-cols-lg-4__6IMK5 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_row-cols-lg-5__1YKX8 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .tindercard_row-cols-lg-6__2JDUO > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-lg-auto__3PqE6 {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .tindercard_col-lg-1__1-_kX {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .tindercard_col-lg-2__YSV1V {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-lg-3__1q0_q {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_col-lg-4__3SAg0 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_col-lg-5__1gO1s {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .tindercard_col-lg-6__v4EpP {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_col-lg-7__2TZWE {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .tindercard_col-lg-8__2c1_P {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .tindercard_col-lg-9__oMAZ0 {
    flex: 0 0 75%;
    max-width: 75%; }
  .tindercard_col-lg-10__2k2Ch {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .tindercard_col-lg-11__39HS5 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .tindercard_col-lg-12__1aoQX {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_order-lg-first__M6oeu {
    order: -1; }
  .tindercard_order-lg-last__3ng_e {
    order: 13; }
  .tindercard_order-lg-0__ElgNs {
    order: 0; }
  .tindercard_order-lg-1__3xeJW {
    order: 1; }
  .tindercard_order-lg-2__WBi87 {
    order: 2; }
  .tindercard_order-lg-3__3LMrD {
    order: 3; }
  .tindercard_order-lg-4__1Kkan {
    order: 4; }
  .tindercard_order-lg-5__1mftu {
    order: 5; }
  .tindercard_order-lg-6__GWaJs {
    order: 6; }
  .tindercard_order-lg-7__3gCwp {
    order: 7; }
  .tindercard_order-lg-8__1efdL {
    order: 8; }
  .tindercard_order-lg-9__2_gtA {
    order: 9; }
  .tindercard_order-lg-10__3Roi6 {
    order: 10; }
  .tindercard_order-lg-11__N01ow {
    order: 11; }
  .tindercard_order-lg-12__1J3Rg {
    order: 12; }
  .tindercard_offset-lg-0__3r_uX {
    margin-left: 0; }
  .tindercard_offset-lg-1__3-ahO {
    margin-left: 8.33333%; }
  .tindercard_offset-lg-2__jHhl- {
    margin-left: 16.66667%; }
  .tindercard_offset-lg-3__1l48x {
    margin-left: 25%; }
  .tindercard_offset-lg-4__1-ZU4 {
    margin-left: 33.33333%; }
  .tindercard_offset-lg-5__1fvMY {
    margin-left: 41.66667%; }
  .tindercard_offset-lg-6__10wes {
    margin-left: 50%; }
  .tindercard_offset-lg-7__1szg3 {
    margin-left: 58.33333%; }
  .tindercard_offset-lg-8__1MbRf {
    margin-left: 66.66667%; }
  .tindercard_offset-lg-9__dBloP {
    margin-left: 75%; }
  .tindercard_offset-lg-10__cEw45 {
    margin-left: 83.33333%; }
  .tindercard_offset-lg-11__1kplP {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .tindercard_col-xl__2DdKI {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .tindercard_row-cols-xl-1__OmdGg > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_row-cols-xl-2__1v1Je > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_row-cols-xl-3__uT_jq > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_row-cols-xl-4__3c-WG > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_row-cols-xl-5__3U2JN > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .tindercard_row-cols-xl-6__MBtJi > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-xl-auto__3UQ8m {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .tindercard_col-xl-1__2FIiA {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .tindercard_col-xl-2__6c2lW {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .tindercard_col-xl-3__1Et_2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .tindercard_col-xl-4__1V5gl {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .tindercard_col-xl-5__2CIs1 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .tindercard_col-xl-6__1gU4R {
    flex: 0 0 50%;
    max-width: 50%; }
  .tindercard_col-xl-7__1E5z1 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .tindercard_col-xl-8__1HWvp {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .tindercard_col-xl-9__3xjbc {
    flex: 0 0 75%;
    max-width: 75%; }
  .tindercard_col-xl-10__1rGt1 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .tindercard_col-xl-11__1g-_n {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .tindercard_col-xl-12__67KaM {
    flex: 0 0 100%;
    max-width: 100%; }
  .tindercard_order-xl-first__2wMQm {
    order: -1; }
  .tindercard_order-xl-last__nWK1k {
    order: 13; }
  .tindercard_order-xl-0__255bF {
    order: 0; }
  .tindercard_order-xl-1__2IdcU {
    order: 1; }
  .tindercard_order-xl-2__1Fcz9 {
    order: 2; }
  .tindercard_order-xl-3__39F-_ {
    order: 3; }
  .tindercard_order-xl-4__z3YZH {
    order: 4; }
  .tindercard_order-xl-5__1AKSU {
    order: 5; }
  .tindercard_order-xl-6__1zdvm {
    order: 6; }
  .tindercard_order-xl-7__14BI1 {
    order: 7; }
  .tindercard_order-xl-8__2iWM6 {
    order: 8; }
  .tindercard_order-xl-9__3rRY9 {
    order: 9; }
  .tindercard_order-xl-10__1Ua_J {
    order: 10; }
  .tindercard_order-xl-11__1vp7Z {
    order: 11; }
  .tindercard_order-xl-12__NKEvO {
    order: 12; }
  .tindercard_offset-xl-0___pTvV {
    margin-left: 0; }
  .tindercard_offset-xl-1__2F4So {
    margin-left: 8.33333%; }
  .tindercard_offset-xl-2__3j-4s {
    margin-left: 16.66667%; }
  .tindercard_offset-xl-3__2Dofr {
    margin-left: 25%; }
  .tindercard_offset-xl-4__35nX4 {
    margin-left: 33.33333%; }
  .tindercard_offset-xl-5__1uAcV {
    margin-left: 41.66667%; }
  .tindercard_offset-xl-6__pPZ2R {
    margin-left: 50%; }
  .tindercard_offset-xl-7__2h55z {
    margin-left: 58.33333%; }
  .tindercard_offset-xl-8__5HXCD {
    margin-left: 66.66667%; }
  .tindercard_offset-xl-9__3XBs1 {
    margin-left: 75%; }
  .tindercard_offset-xl-10__2eBWC {
    margin-left: 83.33333%; }
  .tindercard_offset-xl-11__HuEjY {
    margin-left: 91.66667%; } }

.tindercard_d-none__3zG3c {
  display: none !important; }

.tindercard_d-inline__1RxyN {
  display: inline !important; }

.tindercard_d-inline-block__33SlZ {
  display: inline-block !important; }

.tindercard_d-block__1-esA {
  display: block !important; }

.tindercard_d-table__3jJ0c {
  display: table !important; }

.tindercard_d-table-row__35R1V {
  display: table-row !important; }

.tindercard_d-table-cell__3NcE- {
  display: table-cell !important; }

.tindercard_d-flex__dzl2n {
  display: flex !important; }

.tindercard_d-inline-flex__2_6S9 {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .tindercard_d-sm-none__1xFcx {
    display: none !important; }
  .tindercard_d-sm-inline__1gQ3t {
    display: inline !important; }
  .tindercard_d-sm-inline-block__2WW6r {
    display: inline-block !important; }
  .tindercard_d-sm-block__5UoWz {
    display: block !important; }
  .tindercard_d-sm-table__3Vjlg {
    display: table !important; }
  .tindercard_d-sm-table-row__3tZ54 {
    display: table-row !important; }
  .tindercard_d-sm-table-cell__3EYov {
    display: table-cell !important; }
  .tindercard_d-sm-flex__37KT9 {
    display: flex !important; }
  .tindercard_d-sm-inline-flex__2--Dn {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .tindercard_d-md-none__AHxNc {
    display: none !important; }
  .tindercard_d-md-inline__329Z1 {
    display: inline !important; }
  .tindercard_d-md-inline-block__17h9u {
    display: inline-block !important; }
  .tindercard_d-md-block__3NOYK {
    display: block !important; }
  .tindercard_d-md-table__1O_m2 {
    display: table !important; }
  .tindercard_d-md-table-row__2LEU7 {
    display: table-row !important; }
  .tindercard_d-md-table-cell__34Dm4 {
    display: table-cell !important; }
  .tindercard_d-md-flex__25KTb {
    display: flex !important; }
  .tindercard_d-md-inline-flex__3AEEw {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .tindercard_d-lg-none__4CTVs {
    display: none !important; }
  .tindercard_d-lg-inline__1RGj9 {
    display: inline !important; }
  .tindercard_d-lg-inline-block__3KNX0 {
    display: inline-block !important; }
  .tindercard_d-lg-block__EY5hI {
    display: block !important; }
  .tindercard_d-lg-table__1MbO6 {
    display: table !important; }
  .tindercard_d-lg-table-row__tjrRT {
    display: table-row !important; }
  .tindercard_d-lg-table-cell__1quIT {
    display: table-cell !important; }
  .tindercard_d-lg-flex__1sXH- {
    display: flex !important; }
  .tindercard_d-lg-inline-flex__3lljA {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .tindercard_d-xl-none__--32S {
    display: none !important; }
  .tindercard_d-xl-inline__1zdMp {
    display: inline !important; }
  .tindercard_d-xl-inline-block__27Cyz {
    display: inline-block !important; }
  .tindercard_d-xl-block__1A8kF {
    display: block !important; }
  .tindercard_d-xl-table__3dIA3 {
    display: table !important; }
  .tindercard_d-xl-table-row__2qPmJ {
    display: table-row !important; }
  .tindercard_d-xl-table-cell__bWf4U {
    display: table-cell !important; }
  .tindercard_d-xl-flex__13Eyd {
    display: flex !important; }
  .tindercard_d-xl-inline-flex__2aVLe {
    display: inline-flex !important; } }

@media print {
  .tindercard_d-print-none__3wMJ7 {
    display: none !important; }
  .tindercard_d-print-inline__2f3M7 {
    display: inline !important; }
  .tindercard_d-print-inline-block__2zV1E {
    display: inline-block !important; }
  .tindercard_d-print-block__3vbPT {
    display: block !important; }
  .tindercard_d-print-table__Br9uA {
    display: table !important; }
  .tindercard_d-print-table-row__hFaut {
    display: table-row !important; }
  .tindercard_d-print-table-cell__2k8vR {
    display: table-cell !important; }
  .tindercard_d-print-flex__lkBUF {
    display: flex !important; }
  .tindercard_d-print-inline-flex__2E_7u {
    display: inline-flex !important; } }

.tindercard_flex-row__2tEYp {
  flex-direction: row !important; }

.tindercard_flex-column__3MRr_ {
  flex-direction: column !important; }

.tindercard_flex-row-reverse__UKpS8 {
  flex-direction: row-reverse !important; }

.tindercard_flex-column-reverse__OtKHW {
  flex-direction: column-reverse !important; }

.tindercard_flex-wrap__3tWzG {
  flex-wrap: wrap !important; }

.tindercard_flex-nowrap__2SDt4 {
  flex-wrap: nowrap !important; }

.tindercard_flex-wrap-reverse__3USKl {
  flex-wrap: wrap-reverse !important; }

.tindercard_flex-fill__3Qzna {
  flex: 1 1 auto !important; }

.tindercard_flex-grow-0__Ij4BG {
  flex-grow: 0 !important; }

.tindercard_flex-grow-1__3lgd9 {
  flex-grow: 1 !important; }

.tindercard_flex-shrink-0__26OJF {
  flex-shrink: 0 !important; }

.tindercard_flex-shrink-1__2wMFX {
  flex-shrink: 1 !important; }

.tindercard_justify-content-start__1BBDV {
  justify-content: flex-start !important; }

.tindercard_justify-content-end__3lMXq {
  justify-content: flex-end !important; }

.tindercard_justify-content-center____uRP {
  justify-content: center !important; }

.tindercard_justify-content-between__2S8Us {
  justify-content: space-between !important; }

.tindercard_justify-content-around__1bBxV {
  justify-content: space-around !important; }

.tindercard_align-items-start__1PZEW {
  align-items: flex-start !important; }

.tindercard_align-items-end__2uRtc {
  align-items: flex-end !important; }

.tindercard_align-items-center__1QeTI {
  align-items: center !important; }

.tindercard_align-items-baseline__1b0ZD {
  align-items: baseline !important; }

.tindercard_align-items-stretch__2J_Sp {
  align-items: stretch !important; }

.tindercard_align-content-start__Uwt37 {
  align-content: flex-start !important; }

.tindercard_align-content-end__3EuIP {
  align-content: flex-end !important; }

.tindercard_align-content-center__Tt2ok {
  align-content: center !important; }

.tindercard_align-content-between__1WA7o {
  align-content: space-between !important; }

.tindercard_align-content-around__3y4Iu {
  align-content: space-around !important; }

.tindercard_align-content-stretch__AigUo {
  align-content: stretch !important; }

.tindercard_align-self-auto__2p-Oc {
  align-self: auto !important; }

.tindercard_align-self-start__yMvGr {
  align-self: flex-start !important; }

.tindercard_align-self-end__3L8qc {
  align-self: flex-end !important; }

.tindercard_align-self-center__2-Gag {
  align-self: center !important; }

.tindercard_align-self-baseline__3CiL2 {
  align-self: baseline !important; }

.tindercard_align-self-stretch__3EIP3 {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .tindercard_flex-sm-row__5ROdH {
    flex-direction: row !important; }
  .tindercard_flex-sm-column__YlE8p {
    flex-direction: column !important; }
  .tindercard_flex-sm-row-reverse__1gvVb {
    flex-direction: row-reverse !important; }
  .tindercard_flex-sm-column-reverse__3VU9x {
    flex-direction: column-reverse !important; }
  .tindercard_flex-sm-wrap__AqJ5C {
    flex-wrap: wrap !important; }
  .tindercard_flex-sm-nowrap__2Vw7z {
    flex-wrap: nowrap !important; }
  .tindercard_flex-sm-wrap-reverse__lE1Kf {
    flex-wrap: wrap-reverse !important; }
  .tindercard_flex-sm-fill__1D0Qr {
    flex: 1 1 auto !important; }
  .tindercard_flex-sm-grow-0__3eE9C {
    flex-grow: 0 !important; }
  .tindercard_flex-sm-grow-1__2J0ZH {
    flex-grow: 1 !important; }
  .tindercard_flex-sm-shrink-0__26bYL {
    flex-shrink: 0 !important; }
  .tindercard_flex-sm-shrink-1__2z_t1 {
    flex-shrink: 1 !important; }
  .tindercard_justify-content-sm-start__3WYGl {
    justify-content: flex-start !important; }
  .tindercard_justify-content-sm-end__3UTIa {
    justify-content: flex-end !important; }
  .tindercard_justify-content-sm-center__2-6sQ {
    justify-content: center !important; }
  .tindercard_justify-content-sm-between__1jZfh {
    justify-content: space-between !important; }
  .tindercard_justify-content-sm-around__T1Coq {
    justify-content: space-around !important; }
  .tindercard_align-items-sm-start__2onGX {
    align-items: flex-start !important; }
  .tindercard_align-items-sm-end__2DaTq {
    align-items: flex-end !important; }
  .tindercard_align-items-sm-center__2wUb_ {
    align-items: center !important; }
  .tindercard_align-items-sm-baseline__3GSG0 {
    align-items: baseline !important; }
  .tindercard_align-items-sm-stretch__mv-jO {
    align-items: stretch !important; }
  .tindercard_align-content-sm-start__1wcRr {
    align-content: flex-start !important; }
  .tindercard_align-content-sm-end__2X4eJ {
    align-content: flex-end !important; }
  .tindercard_align-content-sm-center__3FGlH {
    align-content: center !important; }
  .tindercard_align-content-sm-between__2DOuz {
    align-content: space-between !important; }
  .tindercard_align-content-sm-around__tgJxs {
    align-content: space-around !important; }
  .tindercard_align-content-sm-stretch__3M1st {
    align-content: stretch !important; }
  .tindercard_align-self-sm-auto__1-qN1 {
    align-self: auto !important; }
  .tindercard_align-self-sm-start__2jMP9 {
    align-self: flex-start !important; }
  .tindercard_align-self-sm-end__2k7B8 {
    align-self: flex-end !important; }
  .tindercard_align-self-sm-center__ThM1A {
    align-self: center !important; }
  .tindercard_align-self-sm-baseline__Kpr_1 {
    align-self: baseline !important; }
  .tindercard_align-self-sm-stretch__1Fs6I {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .tindercard_flex-md-row__1-WRr {
    flex-direction: row !important; }
  .tindercard_flex-md-column__vab2Y {
    flex-direction: column !important; }
  .tindercard_flex-md-row-reverse__3Cd3D {
    flex-direction: row-reverse !important; }
  .tindercard_flex-md-column-reverse__1aXxy {
    flex-direction: column-reverse !important; }
  .tindercard_flex-md-wrap__fNs7G {
    flex-wrap: wrap !important; }
  .tindercard_flex-md-nowrap__kZ0Yc {
    flex-wrap: nowrap !important; }
  .tindercard_flex-md-wrap-reverse__1StRf {
    flex-wrap: wrap-reverse !important; }
  .tindercard_flex-md-fill__3L-mo {
    flex: 1 1 auto !important; }
  .tindercard_flex-md-grow-0__2f2NY {
    flex-grow: 0 !important; }
  .tindercard_flex-md-grow-1__118qC {
    flex-grow: 1 !important; }
  .tindercard_flex-md-shrink-0__jAnR2 {
    flex-shrink: 0 !important; }
  .tindercard_flex-md-shrink-1__1CnGh {
    flex-shrink: 1 !important; }
  .tindercard_justify-content-md-start__1e1Ln {
    justify-content: flex-start !important; }
  .tindercard_justify-content-md-end__1IWC9 {
    justify-content: flex-end !important; }
  .tindercard_justify-content-md-center__1WJ7F {
    justify-content: center !important; }
  .tindercard_justify-content-md-between__3ryUh {
    justify-content: space-between !important; }
  .tindercard_justify-content-md-around__1mSmy {
    justify-content: space-around !important; }
  .tindercard_align-items-md-start__1MV-H {
    align-items: flex-start !important; }
  .tindercard_align-items-md-end__2c9ax {
    align-items: flex-end !important; }
  .tindercard_align-items-md-center__1A9aY {
    align-items: center !important; }
  .tindercard_align-items-md-baseline__Umejp {
    align-items: baseline !important; }
  .tindercard_align-items-md-stretch__2VUji {
    align-items: stretch !important; }
  .tindercard_align-content-md-start__3RKdI {
    align-content: flex-start !important; }
  .tindercard_align-content-md-end__1l8yv {
    align-content: flex-end !important; }
  .tindercard_align-content-md-center__P2Pvb {
    align-content: center !important; }
  .tindercard_align-content-md-between__2Br4v {
    align-content: space-between !important; }
  .tindercard_align-content-md-around__1Uhca {
    align-content: space-around !important; }
  .tindercard_align-content-md-stretch__3vkYW {
    align-content: stretch !important; }
  .tindercard_align-self-md-auto__14tCF {
    align-self: auto !important; }
  .tindercard_align-self-md-start__3ta-R {
    align-self: flex-start !important; }
  .tindercard_align-self-md-end__3_upx {
    align-self: flex-end !important; }
  .tindercard_align-self-md-center__1W3e6 {
    align-self: center !important; }
  .tindercard_align-self-md-baseline__2caKg {
    align-self: baseline !important; }
  .tindercard_align-self-md-stretch__1TgBx {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .tindercard_flex-lg-row__3MnR1 {
    flex-direction: row !important; }
  .tindercard_flex-lg-column__2zoYn {
    flex-direction: column !important; }
  .tindercard_flex-lg-row-reverse__3_Znh {
    flex-direction: row-reverse !important; }
  .tindercard_flex-lg-column-reverse__1cxvP {
    flex-direction: column-reverse !important; }
  .tindercard_flex-lg-wrap__CgwPr {
    flex-wrap: wrap !important; }
  .tindercard_flex-lg-nowrap__1BRYt {
    flex-wrap: nowrap !important; }
  .tindercard_flex-lg-wrap-reverse__3qytF {
    flex-wrap: wrap-reverse !important; }
  .tindercard_flex-lg-fill__18FAB {
    flex: 1 1 auto !important; }
  .tindercard_flex-lg-grow-0__186bU {
    flex-grow: 0 !important; }
  .tindercard_flex-lg-grow-1__3Coej {
    flex-grow: 1 !important; }
  .tindercard_flex-lg-shrink-0__3OA3F {
    flex-shrink: 0 !important; }
  .tindercard_flex-lg-shrink-1__3hcuw {
    flex-shrink: 1 !important; }
  .tindercard_justify-content-lg-start__3tP39 {
    justify-content: flex-start !important; }
  .tindercard_justify-content-lg-end__1l42X {
    justify-content: flex-end !important; }
  .tindercard_justify-content-lg-center__2fWAN {
    justify-content: center !important; }
  .tindercard_justify-content-lg-between__32FZR {
    justify-content: space-between !important; }
  .tindercard_justify-content-lg-around__uGQeo {
    justify-content: space-around !important; }
  .tindercard_align-items-lg-start__XFrM- {
    align-items: flex-start !important; }
  .tindercard_align-items-lg-end__1aEsx {
    align-items: flex-end !important; }
  .tindercard_align-items-lg-center__3VRaK {
    align-items: center !important; }
  .tindercard_align-items-lg-baseline__1VbcP {
    align-items: baseline !important; }
  .tindercard_align-items-lg-stretch__qgFEV {
    align-items: stretch !important; }
  .tindercard_align-content-lg-start__2CHf5 {
    align-content: flex-start !important; }
  .tindercard_align-content-lg-end__1R6TP {
    align-content: flex-end !important; }
  .tindercard_align-content-lg-center__fovVq {
    align-content: center !important; }
  .tindercard_align-content-lg-between__2uQs7 {
    align-content: space-between !important; }
  .tindercard_align-content-lg-around__8gSn6 {
    align-content: space-around !important; }
  .tindercard_align-content-lg-stretch__1BAqP {
    align-content: stretch !important; }
  .tindercard_align-self-lg-auto__1jsWU {
    align-self: auto !important; }
  .tindercard_align-self-lg-start__2ypOW {
    align-self: flex-start !important; }
  .tindercard_align-self-lg-end__3vOup {
    align-self: flex-end !important; }
  .tindercard_align-self-lg-center__1r13A {
    align-self: center !important; }
  .tindercard_align-self-lg-baseline__17z8F {
    align-self: baseline !important; }
  .tindercard_align-self-lg-stretch__1Q5Gx {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .tindercard_flex-xl-row__3NY2X {
    flex-direction: row !important; }
  .tindercard_flex-xl-column__fQxWT {
    flex-direction: column !important; }
  .tindercard_flex-xl-row-reverse__vknAX {
    flex-direction: row-reverse !important; }
  .tindercard_flex-xl-column-reverse__3i5lx {
    flex-direction: column-reverse !important; }
  .tindercard_flex-xl-wrap__1pVFU {
    flex-wrap: wrap !important; }
  .tindercard_flex-xl-nowrap__1Nsm4 {
    flex-wrap: nowrap !important; }
  .tindercard_flex-xl-wrap-reverse__1PvfA {
    flex-wrap: wrap-reverse !important; }
  .tindercard_flex-xl-fill__1ksyl {
    flex: 1 1 auto !important; }
  .tindercard_flex-xl-grow-0__32UZM {
    flex-grow: 0 !important; }
  .tindercard_flex-xl-grow-1__3FiA3 {
    flex-grow: 1 !important; }
  .tindercard_flex-xl-shrink-0__1gV3p {
    flex-shrink: 0 !important; }
  .tindercard_flex-xl-shrink-1__1iB7f {
    flex-shrink: 1 !important; }
  .tindercard_justify-content-xl-start__1cILV {
    justify-content: flex-start !important; }
  .tindercard_justify-content-xl-end__1rRa- {
    justify-content: flex-end !important; }
  .tindercard_justify-content-xl-center__29DmZ {
    justify-content: center !important; }
  .tindercard_justify-content-xl-between__ZMIs8 {
    justify-content: space-between !important; }
  .tindercard_justify-content-xl-around__1I6V1 {
    justify-content: space-around !important; }
  .tindercard_align-items-xl-start__1kv-H {
    align-items: flex-start !important; }
  .tindercard_align-items-xl-end__CnIZU {
    align-items: flex-end !important; }
  .tindercard_align-items-xl-center__EjPSA {
    align-items: center !important; }
  .tindercard_align-items-xl-baseline__r35Mm {
    align-items: baseline !important; }
  .tindercard_align-items-xl-stretch__3evCa {
    align-items: stretch !important; }
  .tindercard_align-content-xl-start__1E1Qc {
    align-content: flex-start !important; }
  .tindercard_align-content-xl-end__3TaWn {
    align-content: flex-end !important; }
  .tindercard_align-content-xl-center__3yFqD {
    align-content: center !important; }
  .tindercard_align-content-xl-between__21Ksh {
    align-content: space-between !important; }
  .tindercard_align-content-xl-around__3GWP7 {
    align-content: space-around !important; }
  .tindercard_align-content-xl-stretch__1Ly_A {
    align-content: stretch !important; }
  .tindercard_align-self-xl-auto__1aNLt {
    align-self: auto !important; }
  .tindercard_align-self-xl-start__3BjQb {
    align-self: flex-start !important; }
  .tindercard_align-self-xl-end__2jCIC {
    align-self: flex-end !important; }
  .tindercard_align-self-xl-center__19nu6 {
    align-self: center !important; }
  .tindercard_align-self-xl-baseline__H2Poz {
    align-self: baseline !important; }
  .tindercard_align-self-xl-stretch__3ZDmm {
    align-self: stretch !important; } }

.tindercard_m-0__Lbf6Z {
  margin: 0 !important; }

.tindercard_mt-0__2Lgsg,
.tindercard_my-0__2DcJy {
  margin-top: 0 !important; }

.tindercard_mr-0__xFKYm,
.tindercard_mx-0__2Z_fx {
  margin-right: 0 !important; }

.tindercard_mb-0__1pXQj,
.tindercard_my-0__2DcJy {
  margin-bottom: 0 !important; }

.tindercard_ml-0__t2EMr,
.tindercard_mx-0__2Z_fx {
  margin-left: 0 !important; }

.tindercard_m-1__ilspd {
  margin: 0.25rem !important; }

.tindercard_mt-1__3E-dr,
.tindercard_my-1__3E1gN {
  margin-top: 0.25rem !important; }

.tindercard_mr-1__jwXIq,
.tindercard_mx-1__20Yv8 {
  margin-right: 0.25rem !important; }

.tindercard_mb-1__2eIJN,
.tindercard_my-1__3E1gN {
  margin-bottom: 0.25rem !important; }

.tindercard_ml-1__26iI9,
.tindercard_mx-1__20Yv8 {
  margin-left: 0.25rem !important; }

.tindercard_m-2__2t2F1 {
  margin: 0.5rem !important; }

.tindercard_mt-2__2yIiE,
.tindercard_my-2__cLdBe {
  margin-top: 0.5rem !important; }

.tindercard_mr-2__1yaUL,
.tindercard_mx-2__1TKQe {
  margin-right: 0.5rem !important; }

.tindercard_mb-2__3o2tu,
.tindercard_my-2__cLdBe {
  margin-bottom: 0.5rem !important; }

.tindercard_ml-2__1Ag2y,
.tindercard_mx-2__1TKQe {
  margin-left: 0.5rem !important; }

.tindercard_m-3__3VB2O {
  margin: 1rem !important; }

.tindercard_mt-3__2eq9H,
.tindercard_my-3__Coimv {
  margin-top: 1rem !important; }

.tindercard_mr-3__3uV0d,
.tindercard_mx-3__3SThn {
  margin-right: 1rem !important; }

.tindercard_mb-3__nv-q9,
.tindercard_my-3__Coimv {
  margin-bottom: 1rem !important; }

.tindercard_ml-3__NSpY1,
.tindercard_mx-3__3SThn {
  margin-left: 1rem !important; }

.tindercard_m-4__1XvDE {
  margin: 1.5rem !important; }

.tindercard_mt-4__2hQGa,
.tindercard_my-4__2Rcv2 {
  margin-top: 1.5rem !important; }

.tindercard_mr-4__3iM49,
.tindercard_mx-4__3L4CD {
  margin-right: 1.5rem !important; }

.tindercard_mb-4__3MYVx,
.tindercard_my-4__2Rcv2 {
  margin-bottom: 1.5rem !important; }

.tindercard_ml-4__7rucb,
.tindercard_mx-4__3L4CD {
  margin-left: 1.5rem !important; }

.tindercard_m-5__kW2i4 {
  margin: 3rem !important; }

.tindercard_mt-5__2F31X,
.tindercard_my-5__uNBhn {
  margin-top: 3rem !important; }

.tindercard_mr-5__2jI-3,
.tindercard_mx-5__PFih9 {
  margin-right: 3rem !important; }

.tindercard_mb-5__1KfpC,
.tindercard_my-5__uNBhn {
  margin-bottom: 3rem !important; }

.tindercard_ml-5___BkVC,
.tindercard_mx-5__PFih9 {
  margin-left: 3rem !important; }

.tindercard_p-0__3CNJ4 {
  padding: 0 !important; }

.tindercard_pt-0__5aQLF,
.tindercard_py-0__4mxjy {
  padding-top: 0 !important; }

.tindercard_pr-0__Zj6Tb,
.tindercard_px-0__1OltC {
  padding-right: 0 !important; }

.tindercard_pb-0__366qk,
.tindercard_py-0__4mxjy {
  padding-bottom: 0 !important; }

.tindercard_pl-0__3KNQm,
.tindercard_px-0__1OltC {
  padding-left: 0 !important; }

.tindercard_p-1__3g241 {
  padding: 0.25rem !important; }

.tindercard_pt-1__2993W,
.tindercard_py-1__17nqx {
  padding-top: 0.25rem !important; }

.tindercard_pr-1__TpLUu,
.tindercard_px-1__2a6TE {
  padding-right: 0.25rem !important; }

.tindercard_pb-1__1UZoF,
.tindercard_py-1__17nqx {
  padding-bottom: 0.25rem !important; }

.tindercard_pl-1__eX2bC,
.tindercard_px-1__2a6TE {
  padding-left: 0.25rem !important; }

.tindercard_p-2__25jB_ {
  padding: 0.5rem !important; }

.tindercard_pt-2__kjaP2,
.tindercard_py-2__1WCyI {
  padding-top: 0.5rem !important; }

.tindercard_pr-2__2odvY,
.tindercard_px-2__2y8j3 {
  padding-right: 0.5rem !important; }

.tindercard_pb-2__3u7OO,
.tindercard_py-2__1WCyI {
  padding-bottom: 0.5rem !important; }

.tindercard_pl-2__3DIy3,
.tindercard_px-2__2y8j3 {
  padding-left: 0.5rem !important; }

.tindercard_p-3__Pm2IJ {
  padding: 1rem !important; }

.tindercard_pt-3__3grP_,
.tindercard_py-3__2LZeI {
  padding-top: 1rem !important; }

.tindercard_pr-3__yhWiY,
.tindercard_px-3__2MMfr {
  padding-right: 1rem !important; }

.tindercard_pb-3__36V2w,
.tindercard_py-3__2LZeI {
  padding-bottom: 1rem !important; }

.tindercard_pl-3__34MpK,
.tindercard_px-3__2MMfr {
  padding-left: 1rem !important; }

.tindercard_p-4__1-D6m {
  padding: 1.5rem !important; }

.tindercard_pt-4__3kVqy,
.tindercard_py-4__2NTQq {
  padding-top: 1.5rem !important; }

.tindercard_pr-4__3b28z,
.tindercard_px-4__3GOhz {
  padding-right: 1.5rem !important; }

.tindercard_pb-4__396Xp,
.tindercard_py-4__2NTQq {
  padding-bottom: 1.5rem !important; }

.tindercard_pl-4__2ryhl,
.tindercard_px-4__3GOhz {
  padding-left: 1.5rem !important; }

.tindercard_p-5__-hYSK {
  padding: 3rem !important; }

.tindercard_pt-5__Rr1gY,
.tindercard_py-5__2k8ht {
  padding-top: 3rem !important; }

.tindercard_pr-5__PPfic,
.tindercard_px-5__3Fveu {
  padding-right: 3rem !important; }

.tindercard_pb-5__1qwbe,
.tindercard_py-5__2k8ht {
  padding-bottom: 3rem !important; }

.tindercard_pl-5__2Aehz,
.tindercard_px-5__3Fveu {
  padding-left: 3rem !important; }

.tindercard_m-n1__1gWuR {
  margin: -0.25rem !important; }

.tindercard_mt-n1__CCJr4,
.tindercard_my-n1__1X3Bn {
  margin-top: -0.25rem !important; }

.tindercard_mr-n1__-W1IF,
.tindercard_mx-n1__1yfOo {
  margin-right: -0.25rem !important; }

.tindercard_mb-n1__WqY-7,
.tindercard_my-n1__1X3Bn {
  margin-bottom: -0.25rem !important; }

.tindercard_ml-n1__39K1X,
.tindercard_mx-n1__1yfOo {
  margin-left: -0.25rem !important; }

.tindercard_m-n2__37gNZ {
  margin: -0.5rem !important; }

.tindercard_mt-n2__UtgO_,
.tindercard_my-n2__SQN9p {
  margin-top: -0.5rem !important; }

.tindercard_mr-n2__1O9se,
.tindercard_mx-n2__25vcI {
  margin-right: -0.5rem !important; }

.tindercard_mb-n2__3yEaK,
.tindercard_my-n2__SQN9p {
  margin-bottom: -0.5rem !important; }

.tindercard_ml-n2__Fkc_F,
.tindercard_mx-n2__25vcI {
  margin-left: -0.5rem !important; }

.tindercard_m-n3__12D-V {
  margin: -1rem !important; }

.tindercard_mt-n3__Bznid,
.tindercard_my-n3__2sS98 {
  margin-top: -1rem !important; }

.tindercard_mr-n3__39K8p,
.tindercard_mx-n3__2GqgB {
  margin-right: -1rem !important; }

.tindercard_mb-n3__3WvQE,
.tindercard_my-n3__2sS98 {
  margin-bottom: -1rem !important; }

.tindercard_ml-n3__2P-Gi,
.tindercard_mx-n3__2GqgB {
  margin-left: -1rem !important; }

.tindercard_m-n4__Gtk27 {
  margin: -1.5rem !important; }

.tindercard_mt-n4__USMDw,
.tindercard_my-n4__eMCWg {
  margin-top: -1.5rem !important; }

.tindercard_mr-n4__3zCIR,
.tindercard_mx-n4__3--6w {
  margin-right: -1.5rem !important; }

.tindercard_mb-n4__1OFLY,
.tindercard_my-n4__eMCWg {
  margin-bottom: -1.5rem !important; }

.tindercard_ml-n4__1LcZM,
.tindercard_mx-n4__3--6w {
  margin-left: -1.5rem !important; }

.tindercard_m-n5__3YNGm {
  margin: -3rem !important; }

.tindercard_mt-n5__1ha9r,
.tindercard_my-n5__1xmxC {
  margin-top: -3rem !important; }

.tindercard_mr-n5__2LoTr,
.tindercard_mx-n5__21YZb {
  margin-right: -3rem !important; }

.tindercard_mb-n5__-e3S0,
.tindercard_my-n5__1xmxC {
  margin-bottom: -3rem !important; }

.tindercard_ml-n5__1x4N1,
.tindercard_mx-n5__21YZb {
  margin-left: -3rem !important; }

.tindercard_m-auto__132-l {
  margin: auto !important; }

.tindercard_mt-auto__NT5G_,
.tindercard_my-auto__UlAEW {
  margin-top: auto !important; }

.tindercard_mr-auto__fDpsY,
.tindercard_mx-auto__2b6tM {
  margin-right: auto !important; }

.tindercard_mb-auto__39n3L,
.tindercard_my-auto__UlAEW {
  margin-bottom: auto !important; }

.tindercard_ml-auto__1EPi2,
.tindercard_mx-auto__2b6tM {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .tindercard_m-sm-0__Zi1XY {
    margin: 0 !important; }
  .tindercard_mt-sm-0__2gA2y,
  .tindercard_my-sm-0__1sWLv {
    margin-top: 0 !important; }
  .tindercard_mr-sm-0__1jaCd,
  .tindercard_mx-sm-0___ZpzN {
    margin-right: 0 !important; }
  .tindercard_mb-sm-0__gx-be,
  .tindercard_my-sm-0__1sWLv {
    margin-bottom: 0 !important; }
  .tindercard_ml-sm-0__1a5eu,
  .tindercard_mx-sm-0___ZpzN {
    margin-left: 0 !important; }
  .tindercard_m-sm-1__2V3Zr {
    margin: 0.25rem !important; }
  .tindercard_mt-sm-1__UDg_F,
  .tindercard_my-sm-1__3Lkq1 {
    margin-top: 0.25rem !important; }
  .tindercard_mr-sm-1__3XLjX,
  .tindercard_mx-sm-1__2340J {
    margin-right: 0.25rem !important; }
  .tindercard_mb-sm-1__1x6hn,
  .tindercard_my-sm-1__3Lkq1 {
    margin-bottom: 0.25rem !important; }
  .tindercard_ml-sm-1__3HPJh,
  .tindercard_mx-sm-1__2340J {
    margin-left: 0.25rem !important; }
  .tindercard_m-sm-2__2soVp {
    margin: 0.5rem !important; }
  .tindercard_mt-sm-2__1mNLi,
  .tindercard_my-sm-2__4KClN {
    margin-top: 0.5rem !important; }
  .tindercard_mr-sm-2__b353D,
  .tindercard_mx-sm-2__3F8lh {
    margin-right: 0.5rem !important; }
  .tindercard_mb-sm-2__1XtXg,
  .tindercard_my-sm-2__4KClN {
    margin-bottom: 0.5rem !important; }
  .tindercard_ml-sm-2__3Lyc3,
  .tindercard_mx-sm-2__3F8lh {
    margin-left: 0.5rem !important; }
  .tindercard_m-sm-3__9HAR6 {
    margin: 1rem !important; }
  .tindercard_mt-sm-3__ckD4s,
  .tindercard_my-sm-3__nJIDC {
    margin-top: 1rem !important; }
  .tindercard_mr-sm-3__frugb,
  .tindercard_mx-sm-3__1SjJC {
    margin-right: 1rem !important; }
  .tindercard_mb-sm-3__3EObd,
  .tindercard_my-sm-3__nJIDC {
    margin-bottom: 1rem !important; }
  .tindercard_ml-sm-3__xrUle,
  .tindercard_mx-sm-3__1SjJC {
    margin-left: 1rem !important; }
  .tindercard_m-sm-4__14Pu6 {
    margin: 1.5rem !important; }
  .tindercard_mt-sm-4__TAhvL,
  .tindercard_my-sm-4__1fXpI {
    margin-top: 1.5rem !important; }
  .tindercard_mr-sm-4__2dU5V,
  .tindercard_mx-sm-4__3TPUN {
    margin-right: 1.5rem !important; }
  .tindercard_mb-sm-4__3wqJA,
  .tindercard_my-sm-4__1fXpI {
    margin-bottom: 1.5rem !important; }
  .tindercard_ml-sm-4__2y63v,
  .tindercard_mx-sm-4__3TPUN {
    margin-left: 1.5rem !important; }
  .tindercard_m-sm-5__L42aa {
    margin: 3rem !important; }
  .tindercard_mt-sm-5__2eezB,
  .tindercard_my-sm-5__2pj6O {
    margin-top: 3rem !important; }
  .tindercard_mr-sm-5__3KVws,
  .tindercard_mx-sm-5__11Z-v {
    margin-right: 3rem !important; }
  .tindercard_mb-sm-5__1vI3Q,
  .tindercard_my-sm-5__2pj6O {
    margin-bottom: 3rem !important; }
  .tindercard_ml-sm-5__2w373,
  .tindercard_mx-sm-5__11Z-v {
    margin-left: 3rem !important; }
  .tindercard_p-sm-0__GmNZh {
    padding: 0 !important; }
  .tindercard_pt-sm-0__19sw0,
  .tindercard_py-sm-0__1Nxx_ {
    padding-top: 0 !important; }
  .tindercard_pr-sm-0__-L0Hs,
  .tindercard_px-sm-0__3auQf {
    padding-right: 0 !important; }
  .tindercard_pb-sm-0__1yG6t,
  .tindercard_py-sm-0__1Nxx_ {
    padding-bottom: 0 !important; }
  .tindercard_pl-sm-0__38egC,
  .tindercard_px-sm-0__3auQf {
    padding-left: 0 !important; }
  .tindercard_p-sm-1__3TAmm {
    padding: 0.25rem !important; }
  .tindercard_pt-sm-1__3w-bz,
  .tindercard_py-sm-1__21YTf {
    padding-top: 0.25rem !important; }
  .tindercard_pr-sm-1__2Wqe1,
  .tindercard_px-sm-1__2p9DZ {
    padding-right: 0.25rem !important; }
  .tindercard_pb-sm-1__3VeVP,
  .tindercard_py-sm-1__21YTf {
    padding-bottom: 0.25rem !important; }
  .tindercard_pl-sm-1__3Nd2y,
  .tindercard_px-sm-1__2p9DZ {
    padding-left: 0.25rem !important; }
  .tindercard_p-sm-2__7dvA_ {
    padding: 0.5rem !important; }
  .tindercard_pt-sm-2__BtJlo,
  .tindercard_py-sm-2__27hVG {
    padding-top: 0.5rem !important; }
  .tindercard_pr-sm-2__2QjQJ,
  .tindercard_px-sm-2__1vmUe {
    padding-right: 0.5rem !important; }
  .tindercard_pb-sm-2__fNKf5,
  .tindercard_py-sm-2__27hVG {
    padding-bottom: 0.5rem !important; }
  .tindercard_pl-sm-2__3-uMi,
  .tindercard_px-sm-2__1vmUe {
    padding-left: 0.5rem !important; }
  .tindercard_p-sm-3__3te8A {
    padding: 1rem !important; }
  .tindercard_pt-sm-3__2PrhD,
  .tindercard_py-sm-3__2k9FK {
    padding-top: 1rem !important; }
  .tindercard_pr-sm-3__1c_fL,
  .tindercard_px-sm-3__180Ms {
    padding-right: 1rem !important; }
  .tindercard_pb-sm-3__42cBT,
  .tindercard_py-sm-3__2k9FK {
    padding-bottom: 1rem !important; }
  .tindercard_pl-sm-3__2JXrF,
  .tindercard_px-sm-3__180Ms {
    padding-left: 1rem !important; }
  .tindercard_p-sm-4__1oWjd {
    padding: 1.5rem !important; }
  .tindercard_pt-sm-4__-mzKa,
  .tindercard_py-sm-4__3MwTF {
    padding-top: 1.5rem !important; }
  .tindercard_pr-sm-4__3ax5L,
  .tindercard_px-sm-4__160zp {
    padding-right: 1.5rem !important; }
  .tindercard_pb-sm-4__KmJIm,
  .tindercard_py-sm-4__3MwTF {
    padding-bottom: 1.5rem !important; }
  .tindercard_pl-sm-4__nbpoF,
  .tindercard_px-sm-4__160zp {
    padding-left: 1.5rem !important; }
  .tindercard_p-sm-5__MSsm4 {
    padding: 3rem !important; }
  .tindercard_pt-sm-5__178wf,
  .tindercard_py-sm-5__2iJl1 {
    padding-top: 3rem !important; }
  .tindercard_pr-sm-5__ymlBe,
  .tindercard_px-sm-5__2YvsI {
    padding-right: 3rem !important; }
  .tindercard_pb-sm-5__15uvT,
  .tindercard_py-sm-5__2iJl1 {
    padding-bottom: 3rem !important; }
  .tindercard_pl-sm-5__2u-79,
  .tindercard_px-sm-5__2YvsI {
    padding-left: 3rem !important; }
  .tindercard_m-sm-n1__1Su07 {
    margin: -0.25rem !important; }
  .tindercard_mt-sm-n1__1205T,
  .tindercard_my-sm-n1__34ktM {
    margin-top: -0.25rem !important; }
  .tindercard_mr-sm-n1__274HB,
  .tindercard_mx-sm-n1__qjLdf {
    margin-right: -0.25rem !important; }
  .tindercard_mb-sm-n1__2RzVu,
  .tindercard_my-sm-n1__34ktM {
    margin-bottom: -0.25rem !important; }
  .tindercard_ml-sm-n1__3LZjH,
  .tindercard_mx-sm-n1__qjLdf {
    margin-left: -0.25rem !important; }
  .tindercard_m-sm-n2__863E3 {
    margin: -0.5rem !important; }
  .tindercard_mt-sm-n2__29yII,
  .tindercard_my-sm-n2__3Fyg0 {
    margin-top: -0.5rem !important; }
  .tindercard_mr-sm-n2__1MYyg,
  .tindercard_mx-sm-n2__3tK5b {
    margin-right: -0.5rem !important; }
  .tindercard_mb-sm-n2__1H44b,
  .tindercard_my-sm-n2__3Fyg0 {
    margin-bottom: -0.5rem !important; }
  .tindercard_ml-sm-n2__3EooC,
  .tindercard_mx-sm-n2__3tK5b {
    margin-left: -0.5rem !important; }
  .tindercard_m-sm-n3__sC9j2 {
    margin: -1rem !important; }
  .tindercard_mt-sm-n3__1Oxg1,
  .tindercard_my-sm-n3__32peU {
    margin-top: -1rem !important; }
  .tindercard_mr-sm-n3__19LFh,
  .tindercard_mx-sm-n3__2IsWn {
    margin-right: -1rem !important; }
  .tindercard_mb-sm-n3__1OntW,
  .tindercard_my-sm-n3__32peU {
    margin-bottom: -1rem !important; }
  .tindercard_ml-sm-n3__Z2buv,
  .tindercard_mx-sm-n3__2IsWn {
    margin-left: -1rem !important; }
  .tindercard_m-sm-n4__3v63e {
    margin: -1.5rem !important; }
  .tindercard_mt-sm-n4__3YB9C,
  .tindercard_my-sm-n4__1tLbt {
    margin-top: -1.5rem !important; }
  .tindercard_mr-sm-n4__3VgGb,
  .tindercard_mx-sm-n4__2REtx {
    margin-right: -1.5rem !important; }
  .tindercard_mb-sm-n4__3pJTZ,
  .tindercard_my-sm-n4__1tLbt {
    margin-bottom: -1.5rem !important; }
  .tindercard_ml-sm-n4__3MjpQ,
  .tindercard_mx-sm-n4__2REtx {
    margin-left: -1.5rem !important; }
  .tindercard_m-sm-n5__1SJRC {
    margin: -3rem !important; }
  .tindercard_mt-sm-n5__3WUh2,
  .tindercard_my-sm-n5__hu_BY {
    margin-top: -3rem !important; }
  .tindercard_mr-sm-n5__3HroZ,
  .tindercard_mx-sm-n5__1CsSm {
    margin-right: -3rem !important; }
  .tindercard_mb-sm-n5__3D4HS,
  .tindercard_my-sm-n5__hu_BY {
    margin-bottom: -3rem !important; }
  .tindercard_ml-sm-n5__2UvW0,
  .tindercard_mx-sm-n5__1CsSm {
    margin-left: -3rem !important; }
  .tindercard_m-sm-auto__sTnFm {
    margin: auto !important; }
  .tindercard_mt-sm-auto__1sZdN,
  .tindercard_my-sm-auto__2gEdm {
    margin-top: auto !important; }
  .tindercard_mr-sm-auto__1OdPl,
  .tindercard_mx-sm-auto__rIRLN {
    margin-right: auto !important; }
  .tindercard_mb-sm-auto__30qde,
  .tindercard_my-sm-auto__2gEdm {
    margin-bottom: auto !important; }
  .tindercard_ml-sm-auto__2eR_F,
  .tindercard_mx-sm-auto__rIRLN {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .tindercard_m-md-0__1Ufca {
    margin: 0 !important; }
  .tindercard_mt-md-0__3ZU6U,
  .tindercard_my-md-0__24o1R {
    margin-top: 0 !important; }
  .tindercard_mr-md-0__1Ls7d,
  .tindercard_mx-md-0__1fAdc {
    margin-right: 0 !important; }
  .tindercard_mb-md-0__1kXfO,
  .tindercard_my-md-0__24o1R {
    margin-bottom: 0 !important; }
  .tindercard_ml-md-0__2nzGS,
  .tindercard_mx-md-0__1fAdc {
    margin-left: 0 !important; }
  .tindercard_m-md-1__2rL5G {
    margin: 0.25rem !important; }
  .tindercard_mt-md-1__1S1o_,
  .tindercard_my-md-1__ZezOm {
    margin-top: 0.25rem !important; }
  .tindercard_mr-md-1__2fkhG,
  .tindercard_mx-md-1__2xf0b {
    margin-right: 0.25rem !important; }
  .tindercard_mb-md-1__2g4uK,
  .tindercard_my-md-1__ZezOm {
    margin-bottom: 0.25rem !important; }
  .tindercard_ml-md-1__3l3wn,
  .tindercard_mx-md-1__2xf0b {
    margin-left: 0.25rem !important; }
  .tindercard_m-md-2__1m7lU {
    margin: 0.5rem !important; }
  .tindercard_mt-md-2__3FyHi,
  .tindercard_my-md-2__14Mun {
    margin-top: 0.5rem !important; }
  .tindercard_mr-md-2__2nGSl,
  .tindercard_mx-md-2__3xLNt {
    margin-right: 0.5rem !important; }
  .tindercard_mb-md-2__3tWFn,
  .tindercard_my-md-2__14Mun {
    margin-bottom: 0.5rem !important; }
  .tindercard_ml-md-2__bFLMB,
  .tindercard_mx-md-2__3xLNt {
    margin-left: 0.5rem !important; }
  .tindercard_m-md-3__3iUNR {
    margin: 1rem !important; }
  .tindercard_mt-md-3__3Kvqh,
  .tindercard_my-md-3__IQi2p {
    margin-top: 1rem !important; }
  .tindercard_mr-md-3__1oBuw,
  .tindercard_mx-md-3__1dbS3 {
    margin-right: 1rem !important; }
  .tindercard_mb-md-3__r3vXS,
  .tindercard_my-md-3__IQi2p {
    margin-bottom: 1rem !important; }
  .tindercard_ml-md-3__3nlDG,
  .tindercard_mx-md-3__1dbS3 {
    margin-left: 1rem !important; }
  .tindercard_m-md-4__2-NI- {
    margin: 1.5rem !important; }
  .tindercard_mt-md-4__1loQY,
  .tindercard_my-md-4__20itB {
    margin-top: 1.5rem !important; }
  .tindercard_mr-md-4__2WEtw,
  .tindercard_mx-md-4__3IIa8 {
    margin-right: 1.5rem !important; }
  .tindercard_mb-md-4__1Cl_X,
  .tindercard_my-md-4__20itB {
    margin-bottom: 1.5rem !important; }
  .tindercard_ml-md-4__nn6fh,
  .tindercard_mx-md-4__3IIa8 {
    margin-left: 1.5rem !important; }
  .tindercard_m-md-5__2k0JF {
    margin: 3rem !important; }
  .tindercard_mt-md-5__3WiUW,
  .tindercard_my-md-5__2a-nl {
    margin-top: 3rem !important; }
  .tindercard_mr-md-5__1RURA,
  .tindercard_mx-md-5__2Lwkx {
    margin-right: 3rem !important; }
  .tindercard_mb-md-5__1yKWr,
  .tindercard_my-md-5__2a-nl {
    margin-bottom: 3rem !important; }
  .tindercard_ml-md-5__2uVNc,
  .tindercard_mx-md-5__2Lwkx {
    margin-left: 3rem !important; }
  .tindercard_p-md-0__3j0yf {
    padding: 0 !important; }
  .tindercard_pt-md-0__3yyKv,
  .tindercard_py-md-0__1lYTo {
    padding-top: 0 !important; }
  .tindercard_pr-md-0__pA3S5,
  .tindercard_px-md-0__3UBnw {
    padding-right: 0 !important; }
  .tindercard_pb-md-0__nCP_S,
  .tindercard_py-md-0__1lYTo {
    padding-bottom: 0 !important; }
  .tindercard_pl-md-0__1DNfs,
  .tindercard_px-md-0__3UBnw {
    padding-left: 0 !important; }
  .tindercard_p-md-1__3HYX6 {
    padding: 0.25rem !important; }
  .tindercard_pt-md-1__1niBr,
  .tindercard_py-md-1__W_fiM {
    padding-top: 0.25rem !important; }
  .tindercard_pr-md-1__3_Ary,
  .tindercard_px-md-1__1rMSj {
    padding-right: 0.25rem !important; }
  .tindercard_pb-md-1__2j-nL,
  .tindercard_py-md-1__W_fiM {
    padding-bottom: 0.25rem !important; }
  .tindercard_pl-md-1__2TLMD,
  .tindercard_px-md-1__1rMSj {
    padding-left: 0.25rem !important; }
  .tindercard_p-md-2__1yrHW {
    padding: 0.5rem !important; }
  .tindercard_pt-md-2__1kDat,
  .tindercard_py-md-2__7ahcx {
    padding-top: 0.5rem !important; }
  .tindercard_pr-md-2__36JoO,
  .tindercard_px-md-2__1Mvj1 {
    padding-right: 0.5rem !important; }
  .tindercard_pb-md-2__ffKeG,
  .tindercard_py-md-2__7ahcx {
    padding-bottom: 0.5rem !important; }
  .tindercard_pl-md-2__1R5QE,
  .tindercard_px-md-2__1Mvj1 {
    padding-left: 0.5rem !important; }
  .tindercard_p-md-3__ZpkCO {
    padding: 1rem !important; }
  .tindercard_pt-md-3__26w7l,
  .tindercard_py-md-3__GCatD {
    padding-top: 1rem !important; }
  .tindercard_pr-md-3__3U7vr,
  .tindercard_px-md-3__3ArX_ {
    padding-right: 1rem !important; }
  .tindercard_pb-md-3__1cfVC,
  .tindercard_py-md-3__GCatD {
    padding-bottom: 1rem !important; }
  .tindercard_pl-md-3__wiZ4s,
  .tindercard_px-md-3__3ArX_ {
    padding-left: 1rem !important; }
  .tindercard_p-md-4__1WKii {
    padding: 1.5rem !important; }
  .tindercard_pt-md-4__3gGqA,
  .tindercard_py-md-4__bUnCJ {
    padding-top: 1.5rem !important; }
  .tindercard_pr-md-4__Zv4zD,
  .tindercard_px-md-4__10mGI {
    padding-right: 1.5rem !important; }
  .tindercard_pb-md-4__1bOfF,
  .tindercard_py-md-4__bUnCJ {
    padding-bottom: 1.5rem !important; }
  .tindercard_pl-md-4__qpLjr,
  .tindercard_px-md-4__10mGI {
    padding-left: 1.5rem !important; }
  .tindercard_p-md-5__QAlrJ {
    padding: 3rem !important; }
  .tindercard_pt-md-5__3XvJI,
  .tindercard_py-md-5__1SZgQ {
    padding-top: 3rem !important; }
  .tindercard_pr-md-5__2hvs_,
  .tindercard_px-md-5__Qzk86 {
    padding-right: 3rem !important; }
  .tindercard_pb-md-5__8ePzD,
  .tindercard_py-md-5__1SZgQ {
    padding-bottom: 3rem !important; }
  .tindercard_pl-md-5__3IS3z,
  .tindercard_px-md-5__Qzk86 {
    padding-left: 3rem !important; }
  .tindercard_m-md-n1__1tRIZ {
    margin: -0.25rem !important; }
  .tindercard_mt-md-n1__GE5Y-,
  .tindercard_my-md-n1__2FU7F {
    margin-top: -0.25rem !important; }
  .tindercard_mr-md-n1__3RNoG,
  .tindercard_mx-md-n1__2_ogT {
    margin-right: -0.25rem !important; }
  .tindercard_mb-md-n1__9nNL1,
  .tindercard_my-md-n1__2FU7F {
    margin-bottom: -0.25rem !important; }
  .tindercard_ml-md-n1__WkUoz,
  .tindercard_mx-md-n1__2_ogT {
    margin-left: -0.25rem !important; }
  .tindercard_m-md-n2__uJdxq {
    margin: -0.5rem !important; }
  .tindercard_mt-md-n2__cPoLA,
  .tindercard_my-md-n2__322YZ {
    margin-top: -0.5rem !important; }
  .tindercard_mr-md-n2__24Ehy,
  .tindercard_mx-md-n2__2_y-R {
    margin-right: -0.5rem !important; }
  .tindercard_mb-md-n2__2wkvt,
  .tindercard_my-md-n2__322YZ {
    margin-bottom: -0.5rem !important; }
  .tindercard_ml-md-n2__nf9yn,
  .tindercard_mx-md-n2__2_y-R {
    margin-left: -0.5rem !important; }
  .tindercard_m-md-n3__3uopZ {
    margin: -1rem !important; }
  .tindercard_mt-md-n3__kNZIe,
  .tindercard_my-md-n3__1Rc02 {
    margin-top: -1rem !important; }
  .tindercard_mr-md-n3__2V96Y,
  .tindercard_mx-md-n3__1q7D0 {
    margin-right: -1rem !important; }
  .tindercard_mb-md-n3__2eg5J,
  .tindercard_my-md-n3__1Rc02 {
    margin-bottom: -1rem !important; }
  .tindercard_ml-md-n3__Jzipw,
  .tindercard_mx-md-n3__1q7D0 {
    margin-left: -1rem !important; }
  .tindercard_m-md-n4__1mfbt {
    margin: -1.5rem !important; }
  .tindercard_mt-md-n4__2VSBV,
  .tindercard_my-md-n4__3Btz4 {
    margin-top: -1.5rem !important; }
  .tindercard_mr-md-n4__POEc6,
  .tindercard_mx-md-n4__T3NA1 {
    margin-right: -1.5rem !important; }
  .tindercard_mb-md-n4__2KD8z,
  .tindercard_my-md-n4__3Btz4 {
    margin-bottom: -1.5rem !important; }
  .tindercard_ml-md-n4__1TJpQ,
  .tindercard_mx-md-n4__T3NA1 {
    margin-left: -1.5rem !important; }
  .tindercard_m-md-n5__qsDie {
    margin: -3rem !important; }
  .tindercard_mt-md-n5__MdyqX,
  .tindercard_my-md-n5__1MrbX {
    margin-top: -3rem !important; }
  .tindercard_mr-md-n5__35-Qj,
  .tindercard_mx-md-n5__2F79_ {
    margin-right: -3rem !important; }
  .tindercard_mb-md-n5__10TKW,
  .tindercard_my-md-n5__1MrbX {
    margin-bottom: -3rem !important; }
  .tindercard_ml-md-n5__2YGye,
  .tindercard_mx-md-n5__2F79_ {
    margin-left: -3rem !important; }
  .tindercard_m-md-auto__LQQbA {
    margin: auto !important; }
  .tindercard_mt-md-auto__20rTK,
  .tindercard_my-md-auto__2DF6a {
    margin-top: auto !important; }
  .tindercard_mr-md-auto__3s5Rf,
  .tindercard_mx-md-auto__2LpYc {
    margin-right: auto !important; }
  .tindercard_mb-md-auto__3rQ69,
  .tindercard_my-md-auto__2DF6a {
    margin-bottom: auto !important; }
  .tindercard_ml-md-auto__b2haf,
  .tindercard_mx-md-auto__2LpYc {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .tindercard_m-lg-0__1KYvy {
    margin: 0 !important; }
  .tindercard_mt-lg-0__3hFGg,
  .tindercard_my-lg-0__lufVM {
    margin-top: 0 !important; }
  .tindercard_mr-lg-0__1uako,
  .tindercard_mx-lg-0__26gG3 {
    margin-right: 0 !important; }
  .tindercard_mb-lg-0__2uT1i,
  .tindercard_my-lg-0__lufVM {
    margin-bottom: 0 !important; }
  .tindercard_ml-lg-0__-wYmF,
  .tindercard_mx-lg-0__26gG3 {
    margin-left: 0 !important; }
  .tindercard_m-lg-1__2SSRl {
    margin: 0.25rem !important; }
  .tindercard_mt-lg-1__GUZu0,
  .tindercard_my-lg-1__18XVr {
    margin-top: 0.25rem !important; }
  .tindercard_mr-lg-1__zXn7k,
  .tindercard_mx-lg-1__30DSc {
    margin-right: 0.25rem !important; }
  .tindercard_mb-lg-1__1rVYx,
  .tindercard_my-lg-1__18XVr {
    margin-bottom: 0.25rem !important; }
  .tindercard_ml-lg-1__11r09,
  .tindercard_mx-lg-1__30DSc {
    margin-left: 0.25rem !important; }
  .tindercard_m-lg-2__2_m8z {
    margin: 0.5rem !important; }
  .tindercard_mt-lg-2__2Vb75,
  .tindercard_my-lg-2__1-H3J {
    margin-top: 0.5rem !important; }
  .tindercard_mr-lg-2__u63Ws,
  .tindercard_mx-lg-2__1MqrK {
    margin-right: 0.5rem !important; }
  .tindercard_mb-lg-2__3Fmzh,
  .tindercard_my-lg-2__1-H3J {
    margin-bottom: 0.5rem !important; }
  .tindercard_ml-lg-2__NNfW1,
  .tindercard_mx-lg-2__1MqrK {
    margin-left: 0.5rem !important; }
  .tindercard_m-lg-3__3V8zu {
    margin: 1rem !important; }
  .tindercard_mt-lg-3__2cbOL,
  .tindercard_my-lg-3__3mr03 {
    margin-top: 1rem !important; }
  .tindercard_mr-lg-3__JF3Yb,
  .tindercard_mx-lg-3__1ZwXL {
    margin-right: 1rem !important; }
  .tindercard_mb-lg-3__2UkD8,
  .tindercard_my-lg-3__3mr03 {
    margin-bottom: 1rem !important; }
  .tindercard_ml-lg-3__23oOq,
  .tindercard_mx-lg-3__1ZwXL {
    margin-left: 1rem !important; }
  .tindercard_m-lg-4__Zm89A {
    margin: 1.5rem !important; }
  .tindercard_mt-lg-4__AEBLC,
  .tindercard_my-lg-4__1hAzg {
    margin-top: 1.5rem !important; }
  .tindercard_mr-lg-4__36cm2,
  .tindercard_mx-lg-4__2kcT4 {
    margin-right: 1.5rem !important; }
  .tindercard_mb-lg-4__1twXp,
  .tindercard_my-lg-4__1hAzg {
    margin-bottom: 1.5rem !important; }
  .tindercard_ml-lg-4__1o6OT,
  .tindercard_mx-lg-4__2kcT4 {
    margin-left: 1.5rem !important; }
  .tindercard_m-lg-5__1SnlS {
    margin: 3rem !important; }
  .tindercard_mt-lg-5__WJK5w,
  .tindercard_my-lg-5__3hHdG {
    margin-top: 3rem !important; }
  .tindercard_mr-lg-5__syTs3,
  .tindercard_mx-lg-5__1h7iz {
    margin-right: 3rem !important; }
  .tindercard_mb-lg-5__1iWFQ,
  .tindercard_my-lg-5__3hHdG {
    margin-bottom: 3rem !important; }
  .tindercard_ml-lg-5__3tfQ0,
  .tindercard_mx-lg-5__1h7iz {
    margin-left: 3rem !important; }
  .tindercard_p-lg-0__311cJ {
    padding: 0 !important; }
  .tindercard_pt-lg-0__39bn9,
  .tindercard_py-lg-0__1d32z {
    padding-top: 0 !important; }
  .tindercard_pr-lg-0__2SBDu,
  .tindercard_px-lg-0__2H9xL {
    padding-right: 0 !important; }
  .tindercard_pb-lg-0__3iwuZ,
  .tindercard_py-lg-0__1d32z {
    padding-bottom: 0 !important; }
  .tindercard_pl-lg-0__34o6A,
  .tindercard_px-lg-0__2H9xL {
    padding-left: 0 !important; }
  .tindercard_p-lg-1__36d3X {
    padding: 0.25rem !important; }
  .tindercard_pt-lg-1__3MYWX,
  .tindercard_py-lg-1__1pDUp {
    padding-top: 0.25rem !important; }
  .tindercard_pr-lg-1__1c5ig,
  .tindercard_px-lg-1__255Q9 {
    padding-right: 0.25rem !important; }
  .tindercard_pb-lg-1__MqpUa,
  .tindercard_py-lg-1__1pDUp {
    padding-bottom: 0.25rem !important; }
  .tindercard_pl-lg-1__2_d9U,
  .tindercard_px-lg-1__255Q9 {
    padding-left: 0.25rem !important; }
  .tindercard_p-lg-2__1r4K- {
    padding: 0.5rem !important; }
  .tindercard_pt-lg-2__3uytr,
  .tindercard_py-lg-2__Gvuy3 {
    padding-top: 0.5rem !important; }
  .tindercard_pr-lg-2__iUTB_,
  .tindercard_px-lg-2__2cLcL {
    padding-right: 0.5rem !important; }
  .tindercard_pb-lg-2__1OhjE,
  .tindercard_py-lg-2__Gvuy3 {
    padding-bottom: 0.5rem !important; }
  .tindercard_pl-lg-2__3CJYe,
  .tindercard_px-lg-2__2cLcL {
    padding-left: 0.5rem !important; }
  .tindercard_p-lg-3__1cnMc {
    padding: 1rem !important; }
  .tindercard_pt-lg-3__3rOuj,
  .tindercard_py-lg-3__20R8g {
    padding-top: 1rem !important; }
  .tindercard_pr-lg-3__3HpZL,
  .tindercard_px-lg-3__1ui-5 {
    padding-right: 1rem !important; }
  .tindercard_pb-lg-3__CYzDr,
  .tindercard_py-lg-3__20R8g {
    padding-bottom: 1rem !important; }
  .tindercard_pl-lg-3__jYZby,
  .tindercard_px-lg-3__1ui-5 {
    padding-left: 1rem !important; }
  .tindercard_p-lg-4__1fc-Z {
    padding: 1.5rem !important; }
  .tindercard_pt-lg-4__20L3x,
  .tindercard_py-lg-4__3VZgk {
    padding-top: 1.5rem !important; }
  .tindercard_pr-lg-4__rQ7pn,
  .tindercard_px-lg-4__3X4PU {
    padding-right: 1.5rem !important; }
  .tindercard_pb-lg-4__1c784,
  .tindercard_py-lg-4__3VZgk {
    padding-bottom: 1.5rem !important; }
  .tindercard_pl-lg-4__N2efJ,
  .tindercard_px-lg-4__3X4PU {
    padding-left: 1.5rem !important; }
  .tindercard_p-lg-5__37uEj {
    padding: 3rem !important; }
  .tindercard_pt-lg-5__33Xa3,
  .tindercard_py-lg-5__1FbLh {
    padding-top: 3rem !important; }
  .tindercard_pr-lg-5__1OZHM,
  .tindercard_px-lg-5__1nvW6 {
    padding-right: 3rem !important; }
  .tindercard_pb-lg-5__e-tG_,
  .tindercard_py-lg-5__1FbLh {
    padding-bottom: 3rem !important; }
  .tindercard_pl-lg-5__2aBpm,
  .tindercard_px-lg-5__1nvW6 {
    padding-left: 3rem !important; }
  .tindercard_m-lg-n1__2_Pmu {
    margin: -0.25rem !important; }
  .tindercard_mt-lg-n1__3dcD9,
  .tindercard_my-lg-n1__3TbJx {
    margin-top: -0.25rem !important; }
  .tindercard_mr-lg-n1__1K0Gc,
  .tindercard_mx-lg-n1__1KYgU {
    margin-right: -0.25rem !important; }
  .tindercard_mb-lg-n1__28zTy,
  .tindercard_my-lg-n1__3TbJx {
    margin-bottom: -0.25rem !important; }
  .tindercard_ml-lg-n1__1beCH,
  .tindercard_mx-lg-n1__1KYgU {
    margin-left: -0.25rem !important; }
  .tindercard_m-lg-n2__1sFOu {
    margin: -0.5rem !important; }
  .tindercard_mt-lg-n2__qHyW-,
  .tindercard_my-lg-n2__1WUGi {
    margin-top: -0.5rem !important; }
  .tindercard_mr-lg-n2__3Rbca,
  .tindercard_mx-lg-n2__2UMi4 {
    margin-right: -0.5rem !important; }
  .tindercard_mb-lg-n2__1Fuze,
  .tindercard_my-lg-n2__1WUGi {
    margin-bottom: -0.5rem !important; }
  .tindercard_ml-lg-n2__3T9cc,
  .tindercard_mx-lg-n2__2UMi4 {
    margin-left: -0.5rem !important; }
  .tindercard_m-lg-n3__1FLbM {
    margin: -1rem !important; }
  .tindercard_mt-lg-n3__2q2YF,
  .tindercard_my-lg-n3__bS5f1 {
    margin-top: -1rem !important; }
  .tindercard_mr-lg-n3__1jH0z,
  .tindercard_mx-lg-n3__3HAqe {
    margin-right: -1rem !important; }
  .tindercard_mb-lg-n3__34PSC,
  .tindercard_my-lg-n3__bS5f1 {
    margin-bottom: -1rem !important; }
  .tindercard_ml-lg-n3__2SZ6a,
  .tindercard_mx-lg-n3__3HAqe {
    margin-left: -1rem !important; }
  .tindercard_m-lg-n4__3yxRk {
    margin: -1.5rem !important; }
  .tindercard_mt-lg-n4__3aVr9,
  .tindercard_my-lg-n4__1qSLH {
    margin-top: -1.5rem !important; }
  .tindercard_mr-lg-n4__wrKGP,
  .tindercard_mx-lg-n4__1WNBH {
    margin-right: -1.5rem !important; }
  .tindercard_mb-lg-n4__5IwL6,
  .tindercard_my-lg-n4__1qSLH {
    margin-bottom: -1.5rem !important; }
  .tindercard_ml-lg-n4__ALEs-,
  .tindercard_mx-lg-n4__1WNBH {
    margin-left: -1.5rem !important; }
  .tindercard_m-lg-n5__1jROQ {
    margin: -3rem !important; }
  .tindercard_mt-lg-n5__2_tUl,
  .tindercard_my-lg-n5__2D_Ey {
    margin-top: -3rem !important; }
  .tindercard_mr-lg-n5__2Og-h,
  .tindercard_mx-lg-n5__3GeSB {
    margin-right: -3rem !important; }
  .tindercard_mb-lg-n5__5DiKg,
  .tindercard_my-lg-n5__2D_Ey {
    margin-bottom: -3rem !important; }
  .tindercard_ml-lg-n5__11_OZ,
  .tindercard_mx-lg-n5__3GeSB {
    margin-left: -3rem !important; }
  .tindercard_m-lg-auto__2WwCG {
    margin: auto !important; }
  .tindercard_mt-lg-auto__2rOIl,
  .tindercard_my-lg-auto__Uc3Ze {
    margin-top: auto !important; }
  .tindercard_mr-lg-auto__wO4Cy,
  .tindercard_mx-lg-auto__2K0rv {
    margin-right: auto !important; }
  .tindercard_mb-lg-auto__sFqbi,
  .tindercard_my-lg-auto__Uc3Ze {
    margin-bottom: auto !important; }
  .tindercard_ml-lg-auto__36aKF,
  .tindercard_mx-lg-auto__2K0rv {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .tindercard_m-xl-0__1LIvx {
    margin: 0 !important; }
  .tindercard_mt-xl-0__36_9h,
  .tindercard_my-xl-0__3NzmT {
    margin-top: 0 !important; }
  .tindercard_mr-xl-0__1etTB,
  .tindercard_mx-xl-0__3PyxH {
    margin-right: 0 !important; }
  .tindercard_mb-xl-0__NmaYL,
  .tindercard_my-xl-0__3NzmT {
    margin-bottom: 0 !important; }
  .tindercard_ml-xl-0__1xDg2,
  .tindercard_mx-xl-0__3PyxH {
    margin-left: 0 !important; }
  .tindercard_m-xl-1__3pOKD {
    margin: 0.25rem !important; }
  .tindercard_mt-xl-1__SpkEJ,
  .tindercard_my-xl-1__18oHZ {
    margin-top: 0.25rem !important; }
  .tindercard_mr-xl-1__iRvN-,
  .tindercard_mx-xl-1__3jmbv {
    margin-right: 0.25rem !important; }
  .tindercard_mb-xl-1__2IgyJ,
  .tindercard_my-xl-1__18oHZ {
    margin-bottom: 0.25rem !important; }
  .tindercard_ml-xl-1__Pnk2i,
  .tindercard_mx-xl-1__3jmbv {
    margin-left: 0.25rem !important; }
  .tindercard_m-xl-2__3x0fD {
    margin: 0.5rem !important; }
  .tindercard_mt-xl-2__22sVg,
  .tindercard_my-xl-2__ttXHV {
    margin-top: 0.5rem !important; }
  .tindercard_mr-xl-2__1K77t,
  .tindercard_mx-xl-2__3lezW {
    margin-right: 0.5rem !important; }
  .tindercard_mb-xl-2__HbP8p,
  .tindercard_my-xl-2__ttXHV {
    margin-bottom: 0.5rem !important; }
  .tindercard_ml-xl-2__zWe6b,
  .tindercard_mx-xl-2__3lezW {
    margin-left: 0.5rem !important; }
  .tindercard_m-xl-3__1ds6P {
    margin: 1rem !important; }
  .tindercard_mt-xl-3__15gX2,
  .tindercard_my-xl-3__3XS-i {
    margin-top: 1rem !important; }
  .tindercard_mr-xl-3__3wI5X,
  .tindercard_mx-xl-3__J147l {
    margin-right: 1rem !important; }
  .tindercard_mb-xl-3__1QkoK,
  .tindercard_my-xl-3__3XS-i {
    margin-bottom: 1rem !important; }
  .tindercard_ml-xl-3__24yct,
  .tindercard_mx-xl-3__J147l {
    margin-left: 1rem !important; }
  .tindercard_m-xl-4__3Gu1V {
    margin: 1.5rem !important; }
  .tindercard_mt-xl-4__3RNi2,
  .tindercard_my-xl-4__1BG-Z {
    margin-top: 1.5rem !important; }
  .tindercard_mr-xl-4__2N1Hk,
  .tindercard_mx-xl-4__3LK2i {
    margin-right: 1.5rem !important; }
  .tindercard_mb-xl-4__1Co32,
  .tindercard_my-xl-4__1BG-Z {
    margin-bottom: 1.5rem !important; }
  .tindercard_ml-xl-4__22YyR,
  .tindercard_mx-xl-4__3LK2i {
    margin-left: 1.5rem !important; }
  .tindercard_m-xl-5__2SDO1 {
    margin: 3rem !important; }
  .tindercard_mt-xl-5__1r6_G,
  .tindercard_my-xl-5__2jOhM {
    margin-top: 3rem !important; }
  .tindercard_mr-xl-5__n8SHB,
  .tindercard_mx-xl-5__3M36G {
    margin-right: 3rem !important; }
  .tindercard_mb-xl-5__V8R4B,
  .tindercard_my-xl-5__2jOhM {
    margin-bottom: 3rem !important; }
  .tindercard_ml-xl-5__2j5mt,
  .tindercard_mx-xl-5__3M36G {
    margin-left: 3rem !important; }
  .tindercard_p-xl-0__1A33_ {
    padding: 0 !important; }
  .tindercard_pt-xl-0__20C5b,
  .tindercard_py-xl-0__iYsg3 {
    padding-top: 0 !important; }
  .tindercard_pr-xl-0__3hiC_,
  .tindercard_px-xl-0__2RYDy {
    padding-right: 0 !important; }
  .tindercard_pb-xl-0__fwJiV,
  .tindercard_py-xl-0__iYsg3 {
    padding-bottom: 0 !important; }
  .tindercard_pl-xl-0__34QGe,
  .tindercard_px-xl-0__2RYDy {
    padding-left: 0 !important; }
  .tindercard_p-xl-1__27baQ {
    padding: 0.25rem !important; }
  .tindercard_pt-xl-1__1_e1l,
  .tindercard_py-xl-1__AVLHB {
    padding-top: 0.25rem !important; }
  .tindercard_pr-xl-1__1r7DZ,
  .tindercard_px-xl-1__JTTjS {
    padding-right: 0.25rem !important; }
  .tindercard_pb-xl-1__1STFy,
  .tindercard_py-xl-1__AVLHB {
    padding-bottom: 0.25rem !important; }
  .tindercard_pl-xl-1__1MTR3,
  .tindercard_px-xl-1__JTTjS {
    padding-left: 0.25rem !important; }
  .tindercard_p-xl-2__iVTE4 {
    padding: 0.5rem !important; }
  .tindercard_pt-xl-2__2HokU,
  .tindercard_py-xl-2__17Nxm {
    padding-top: 0.5rem !important; }
  .tindercard_pr-xl-2__Goc0d,
  .tindercard_px-xl-2__3N2PO {
    padding-right: 0.5rem !important; }
  .tindercard_pb-xl-2__2W9QD,
  .tindercard_py-xl-2__17Nxm {
    padding-bottom: 0.5rem !important; }
  .tindercard_pl-xl-2__hNhko,
  .tindercard_px-xl-2__3N2PO {
    padding-left: 0.5rem !important; }
  .tindercard_p-xl-3__36B8r {
    padding: 1rem !important; }
  .tindercard_pt-xl-3__sx3ED,
  .tindercard_py-xl-3__3OSPG {
    padding-top: 1rem !important; }
  .tindercard_pr-xl-3__1burM,
  .tindercard_px-xl-3__3NBpD {
    padding-right: 1rem !important; }
  .tindercard_pb-xl-3__2J0Ct,
  .tindercard_py-xl-3__3OSPG {
    padding-bottom: 1rem !important; }
  .tindercard_pl-xl-3__wHXL4,
  .tindercard_px-xl-3__3NBpD {
    padding-left: 1rem !important; }
  .tindercard_p-xl-4__3uuR8 {
    padding: 1.5rem !important; }
  .tindercard_pt-xl-4__ty9rj,
  .tindercard_py-xl-4__3tUa4 {
    padding-top: 1.5rem !important; }
  .tindercard_pr-xl-4__1m5b7,
  .tindercard_px-xl-4__mCpWs {
    padding-right: 1.5rem !important; }
  .tindercard_pb-xl-4__1Wmea,
  .tindercard_py-xl-4__3tUa4 {
    padding-bottom: 1.5rem !important; }
  .tindercard_pl-xl-4__2ehGg,
  .tindercard_px-xl-4__mCpWs {
    padding-left: 1.5rem !important; }
  .tindercard_p-xl-5__3fTrA {
    padding: 3rem !important; }
  .tindercard_pt-xl-5__5UZnA,
  .tindercard_py-xl-5__3mQrQ {
    padding-top: 3rem !important; }
  .tindercard_pr-xl-5__3PINf,
  .tindercard_px-xl-5__3NVcx {
    padding-right: 3rem !important; }
  .tindercard_pb-xl-5__9UYRB,
  .tindercard_py-xl-5__3mQrQ {
    padding-bottom: 3rem !important; }
  .tindercard_pl-xl-5__1fi6n,
  .tindercard_px-xl-5__3NVcx {
    padding-left: 3rem !important; }
  .tindercard_m-xl-n1__2AazM {
    margin: -0.25rem !important; }
  .tindercard_mt-xl-n1__zHoyP,
  .tindercard_my-xl-n1__360Po {
    margin-top: -0.25rem !important; }
  .tindercard_mr-xl-n1__1gLu7,
  .tindercard_mx-xl-n1__OazNd {
    margin-right: -0.25rem !important; }
  .tindercard_mb-xl-n1__38Y-m,
  .tindercard_my-xl-n1__360Po {
    margin-bottom: -0.25rem !important; }
  .tindercard_ml-xl-n1__1lGjK,
  .tindercard_mx-xl-n1__OazNd {
    margin-left: -0.25rem !important; }
  .tindercard_m-xl-n2__a0m78 {
    margin: -0.5rem !important; }
  .tindercard_mt-xl-n2__7fNdG,
  .tindercard_my-xl-n2__9QARz {
    margin-top: -0.5rem !important; }
  .tindercard_mr-xl-n2__1LQ3t,
  .tindercard_mx-xl-n2__2NQS_ {
    margin-right: -0.5rem !important; }
  .tindercard_mb-xl-n2__2dMmV,
  .tindercard_my-xl-n2__9QARz {
    margin-bottom: -0.5rem !important; }
  .tindercard_ml-xl-n2__bHdvO,
  .tindercard_mx-xl-n2__2NQS_ {
    margin-left: -0.5rem !important; }
  .tindercard_m-xl-n3__2L-gv {
    margin: -1rem !important; }
  .tindercard_mt-xl-n3__1_ucl,
  .tindercard_my-xl-n3__1UAaw {
    margin-top: -1rem !important; }
  .tindercard_mr-xl-n3__3bZli,
  .tindercard_mx-xl-n3__3WWYf {
    margin-right: -1rem !important; }
  .tindercard_mb-xl-n3__1NGe0,
  .tindercard_my-xl-n3__1UAaw {
    margin-bottom: -1rem !important; }
  .tindercard_ml-xl-n3__rSxAC,
  .tindercard_mx-xl-n3__3WWYf {
    margin-left: -1rem !important; }
  .tindercard_m-xl-n4__g-VyD {
    margin: -1.5rem !important; }
  .tindercard_mt-xl-n4__3dwFw,
  .tindercard_my-xl-n4__sLy12 {
    margin-top: -1.5rem !important; }
  .tindercard_mr-xl-n4__2x8Pf,
  .tindercard_mx-xl-n4__2xro2 {
    margin-right: -1.5rem !important; }
  .tindercard_mb-xl-n4__2rsHD,
  .tindercard_my-xl-n4__sLy12 {
    margin-bottom: -1.5rem !important; }
  .tindercard_ml-xl-n4__2OQxx,
  .tindercard_mx-xl-n4__2xro2 {
    margin-left: -1.5rem !important; }
  .tindercard_m-xl-n5__1J8rE {
    margin: -3rem !important; }
  .tindercard_mt-xl-n5__uO9Ox,
  .tindercard_my-xl-n5__1jUPZ {
    margin-top: -3rem !important; }
  .tindercard_mr-xl-n5__3lLM7,
  .tindercard_mx-xl-n5__1e4JC {
    margin-right: -3rem !important; }
  .tindercard_mb-xl-n5__1FiSs,
  .tindercard_my-xl-n5__1jUPZ {
    margin-bottom: -3rem !important; }
  .tindercard_ml-xl-n5__1pN18,
  .tindercard_mx-xl-n5__1e4JC {
    margin-left: -3rem !important; }
  .tindercard_m-xl-auto__3qbxr {
    margin: auto !important; }
  .tindercard_mt-xl-auto__32Uj7,
  .tindercard_my-xl-auto__1bFLE {
    margin-top: auto !important; }
  .tindercard_mr-xl-auto__3IzRw,
  .tindercard_mx-xl-auto__7rZ9o {
    margin-right: auto !important; }
  .tindercard_mb-xl-auto__bmtmb,
  .tindercard_my-xl-auto__1bFLE {
    margin-bottom: auto !important; }
  .tindercard_ml-xl-auto__x4Wba,
  .tindercard_mx-xl-auto__7rZ9o {
    margin-left: auto !important; } }

.tindercard_container__rbFcJ {
  padding-left: 24px;
  padding-right: 24px; }

html {
  background: fixed linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 22.4%, #D9D9D9 100%); }

html,
body {
  height: 100%; }

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333; }

#tindercard_root__7pSTp {
  height: 100%; }

a {
  text-decoration: none;
  outline: none; }

h1 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300; }

h2 {
  font-weight: 300;
  font-size: 22px;
  line-height: 30px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.tindercard_container__rbFcJ {
  padding-top: 1rem; }

.tindercard_cardContainer__TFzMR {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 #00000040; }

.tindercard_img__37DJP {
  width: 100%;
  border-radius: 8px 8px 0 0; }

.tindercard_info__wHb5d {
  padding: 1rem 1rem; }
  .tindercard_info__wHb5d h2,
  .tindercard_info__wHb5d p {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0.5em;
    cursor: default; }
    @media (max-width: 540px) {
      .tindercard_info__wHb5d h2,
      .tindercard_info__wHb5d p {
        font-size: 14px; } }
  .tindercard_info__wHb5d h2 {
    color: #b21ed7;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    @media (max-width: 540px) {
      .tindercard_info__wHb5d h2 {
        font-size: 18px; } }
  .tindercard_info__wHb5d p {
    color: #666; }

.tindercard_btnRow__3vkBH {
  padding-top: 4rem;
  display: flex;
  justify-content: space-between; }

.tindercard_btn__qZ5HV {
  cursor: pointer;
  padding-top: 6px;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  border: 0;
  background: #ffffff;
  box-shadow: 0 0 16px 0 #00000040;
  display: inline-block; }

.tindercard_tinderContainer__3YuqJ {
  height: 550px; }
  @media (max-width: 540px) {
    .tindercard_tinderContainer__3YuqJ {
      height: 100vw; } }

.tindercard_cardLoader__tTKm4 {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 50px; }

.sessionHashInput_sessionHashInput__2peRK {
  width: 100%;
  height: 56px;
  position: relative;
  cursor: text; }
  .sessionHashInput_sessionHashInput__2peRK::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: #B21ED7; }
  .sessionHashInput_sessionHashInput__2peRK.sessionHashInput_focused__1laEh .sessionHashInput_inputPlaceholder__3rcMP {
    bottom: 31px;
    color: #B21ED7;
    font-size: 12px;
    line-height: 16px; }

.sessionHashInput_input__2Xv9D {
  height: 20px;
  width: 100%;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  z-index: 1;
  vertical-align: bottom;
  margin-top: 26px; }

.sessionHashInput_inputPlaceholder__3rcMP {
  position: absolute;
  bottom: 10px;
  z-index: -1;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s; }

.sessionHashInput_button__2kvMn {
  background: none;
  border: none;
  padding: 0;
  outline: none; }

.sessionHashInput_input__2Xv9D,
.sessionHashInput_inputPlaceholder__3rcMP {
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto', sans-serif; }

.sessionHashInput_icon__3T93O {
  height: 23px; }

.sessionHashInput_iconContainer__32zOS {
  position: absolute;
  right: 0;
  bottom: 6px; }
  .sessionHashInput_iconContainer__32zOS:not(.sessionHashInput_disabled__1dE5Q) .sessionHashInput_button__2kvMn {
    cursor: pointer; }
  .sessionHashInput_iconContainer__32zOS:not(.sessionHashInput_disabled__1dE5Q) .sessionHashInput_icon__3T93O {
    fill: #B21ED7; }
  .sessionHashInput_iconContainer__32zOS .sessionHashInput_icon__3T93O {
    fill: #CFCFCF; }

.frontPage_titleContainer__2gGcc {
  margin-top: 5px; }

.frontPage_section__3eBQq {
  padding: 24px 0; }
  .frontPage_section__3eBQq .frontPage_title__1S_zA {
    margin: 10px 0; }
  .frontPage_section__3eBQq:not(:last-child) {
    border-bottom: 0.5px solid #D9D9D9; }

.frontPage_newSessionButtonContainer__2CA8f {
  display: flex;
  justify-content: center; }

.currentSessionContainer_currentSession__3TyxS {
  height: 48px;
  display: flex;
  margin-top: 24px;
  color: inherit; }
  .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_currentSessionImage__hH-Go {
    height: 100%;
    border-radius: 50%;
    width: 48px;
    margin-right: 16px;
    overflow: hidden; }
    .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_currentSessionImage__hH-Go img {
      height: 100%; }
  .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_button__Zd-wV {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer; }
  .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_currentSessionInfo__24Cqa {
    flex: 1 1; }
    .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_currentSessionInfo__24Cqa .currentSessionContainer_name__NKLi8 {
      font-size: 16px;
      line-height: 24px;
      margin: 2px 0 0; }
    .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_currentSessionInfo__24Cqa .currentSessionContainer_sessionHash__2izCA {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
      margin: 0; }
  .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_iconContainer__AKWkt {
    display: flex;
    align-items: center; }
    .currentSessionContainer_currentSession__3TyxS .currentSessionContainer_iconContainer__AKWkt .currentSessionContainer_icon__iU4wH {
      height: 23px;
      fill: #B21ED7; }

.login_container__2X9PK {
  padding: 16px 30px; }

.login_slogan__271YW {
  font-size: 24px;
  margin-top: 56px;
  padding-left: 20px; }

.login_logoContainer__2Qvv5 {
  margin-top: 150px; }

.login_loginContainer__3lZkS {
  padding-left: 20px;
  margin-top: 40px; }

.login_loginButton__1KnvD {
  outline: none;
  background-color: #1ED760;
  border-radius: 200px;
  height: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer; }

.login_spotifyLogo__SR2KW {
  margin-right: 8px; }

