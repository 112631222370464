.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  height: 36px;
  border-radius: 18px;
  padding-left: 32px;
  padding-right: 32px;
  outline: none;
  box-shadow: none;
  margin-top: 16px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  &.purple {
    background-color: #b21ed7;
    color: #ffffff;
  }
  &.red {
    background-color: rgb(192, 0, 0);
    color: #ffffff;
  }
  &:disabled {
    background-color: #666666;
    cursor: not-allowed;
  }
}

.hidden {
  &.purple {
    color: #b21ed7;
  }

  &.red {
    color: rgb(192, 0, 0);
  }
}

.loader {
  position: absolute;
}
