$search-transition: all 0.3s ease-in;

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
  h1 {
    margin: 0;
  }
}

.sessionContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .contentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    transition: $search-transition;
    height: 100%;
  }

  &.searchOpen {
    .contentContainer {
      flex: 0;
      height: 0;
    }

    .searchContainer {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  &:not(.searchOpen) {
    .searchContainer {
      flex-grow: 0;
      animation: position 0s;
      animation-delay: 0.1s;
      animation-fill-mode: forwards;
    }
  }
}

.backgroundGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 88px;
  z-index: 1;
  transition: background-position 0.3s linear;

  &.open {
    background-position: 0 45px;
    z-index: 3;
  }
}

.searchContainer {
  z-index: 2;
  width: 100%;
  transition: flex-grow 0.3s, height 0.3s;
}

.progress,
.queue {
  margin-top: 32px;
}

.queue {
  flex: 1;
}

.search {
  width: 100%;
}

.searchBarOpen {
  flex: 1;
}

@keyframes position {
  from {
    position: relative;
  }
  to {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
