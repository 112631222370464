.container {
  padding: 16px 30px;
}

.slogan {
  font-size: 24px;
  margin-top: 56px;
  padding-left: 20px;
}

.logoContainer {
  margin-top: 150px;
}

.loginContainer {
  padding-left: 20px;
  margin-top: 40px;
}

.loginButton {
  outline: none;
  background-color: #1ED760;
  border-radius: 200px;
  height: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.spotifyLogo {
  margin-right: 8px;
}
