@import "../session.module";

.container {
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: text;
  transition: background-position 0.3s linear;
  flex: 0;

  &.active {
    //background-position: 0 88px;
    .input {
      background-color: #CFCFCF;
    }
  }
}

.input {
  display: block;
  border: none;
  height: 56px;
  width: 100%;
  border-radius: 28px;
  background-color: #FFFFFF;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  outline: none;
  transition: $search-transition;
}

.searchResultContainer {
  flex: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 8px;
  transition: flex-grow 0.3s linear;
  height: 0;

  &.active {
    height: 100%;
    flex-grow: 1;
  }
}

.song {
  margin-bottom: 16px;
}

.albumImage {
  overflow: hidden;
  margin-right: 8px;
  height: 48px;
  width: 48px;
  padding-left: 8px;

  img {
    height: 100%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.songName {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.songArtist {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 0;
}

@media (min-width: 720px) {
  .albumImage {
    height: 60px;
    width: 60px;
    margin-right: 16px;
  }
}
