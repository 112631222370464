.container {
  width: 100%;
  height: 2px;
  background: #5B5B5B;
  position: relative;
}

.progress {
  background: #B21ED7;
  width: 0;
  position: absolute;
  height: 100%;
  left: 0;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    background: inherit;
    height: 5px;
    border-radius: 2px;
    right: 0;
    top: 0;
    transform: translate(50%, calc(-50% + 1px));
  }
}
